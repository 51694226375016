import { Button, Icon, Intent } from "@blueprintjs/core";
import React from "react";
import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { AG_TABLE_RIBBON_BUTTON_TOOLTIP_DELAY } from "../../styles/constants";
import { useStoreActions } from "../../hooks/ep";
import { useCanCreateNewCmaxUser } from "../common/use-can-create-new-cmax-user";
import { CreateNewCmaxUser_FormContent } from "./create-new-cmax-user-form-content";
import { FormType, useOpenDrawerForm } from "../../store/model-drawer-form";

export function CreateNewCmaxUserButton() {
  const openDrawer = useOpenDrawerForm();
  const handleSubmit = useStoreActions(
    (a) => a.cmax_user.handleCreateNewCmaxUser
  );
  const canCreateNewCmaxUser = useCanCreateNewCmaxUser();

  const onClick = () => {
    const title = "Please enter details for the new Customax user";
    const initialData = DEFAULT_INITIAL_DATA;
    const ContentComponent = CreateNewCmaxUser_FormContent;
    openDrawer({
      initialData,
      title,
      ContentComponent,
      handleSubmit,
      formType: FormType.CreateNewCmaxUser,
    });
  };

  return (
    canCreateNewCmaxUser && (
      <Tooltip
        title={"Create new Customax user"}
        placement={"topLeft" as TooltipPlacement}
        mouseEnterDelay={AG_TABLE_RIBBON_BUTTON_TOOLTIP_DELAY}
      >
        <Button
          css={`
            margin-left: 8px;
            margin-right: 8px;
          `}
          small
          minimal
          intent={Intent.SUCCESS}
          icon={<Icon icon="new-person" />}
          onClick={onClick}
        />
      </Tooltip>
    )
  );
}

const DEFAULT_FIELD_NAME_2_STATE = {
  email: "",
  first_name: "",
  last_name: "",
  role: "",
  timezone: "",
};
const DEFAULT_INITIAL_DATA = { fieldName2Value: DEFAULT_FIELD_NAME_2_STATE };

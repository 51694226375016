import { Intent, Position, Toaster } from "@blueprintjs/core";

/** Singleton toaster instance. Create separate instances for different options. */
export const AppToaster = Toaster.create({
  className: "recipe-toaster",
  position: Position.TOP,
});

function factory(intent: Intent) {
  return (message: string, timeoutSeconds?: number): string =>
    AppToaster.show({
      message,
      intent,
      ...(timeoutSeconds ? { timeout: timeoutSeconds * 1000 } : {}),
    });
}

// Meant to mimic `antd.message`
export const toaster = {
  success: factory(Intent.SUCCESS),
  primary: factory(Intent.PRIMARY),
  info: factory(Intent.NONE),
  warning: factory(Intent.WARNING),
  warn: factory(Intent.WARNING),
  error: factory(Intent.DANGER),
  danger: factory(Intent.DANGER),
};

import { createStore, Store } from "easy-peasy";
import { model, StoreModel } from "./model";
import { firebase } from "../helpers/fbase";
// import { reactReduxFirebase } from "react-redux-firebase";
import { logger } from "../middleware/logger.middleware";

const isDevEnv =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const epStore: Store<StoreModel> = createStore(model, {
  middleware: isDevEnv ? [logger] : undefined,
  devTools: true,
  // // @ts-ignore
  // enhancers: [reactReduxFirebase(firebase, rrfConfig)],
});

const rrfConfig = {};
const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: epStore.dispatch,
};

// Wrapping dev only code like this normally gets stripped out by bundlers
// such as Webpack when creating a production build.
if (isDevEnv) {
  if ((module as any).hot) {
    (module as any).hot.accept("./model", () => {
      epStore.reconfigure(model); // 👈 Here is the magic
    });
  }
}

export { epStore, rrfConfig, rrfProps };

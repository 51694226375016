// This must be the first line in src/index.js
import "./index.less";
import "./polyfills";
import "./disable-console-log-in-production.js";
import "./index.css";
import "./ag-grid-customization.scss";
import "./json_parseMore.js";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { epStore, rrfProps } from "./store";
import { firebase as fbase } from "./helpers/fbase";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { StoreProvider } from "easy-peasy";
import { AgTableThemeProvider } from "./components/AgTable/theming";
import reportWebVitals from "./reportWebVitals";

// @ts-ignore
const window_Cypress = window.Cypress;

if (
  window_Cypress ||
  !process.env.NODE_ENV ||
  process.env.NODE_ENV !== "production"
) {
  // @ts-ignore
  window.store = epStore;
  // @ts-ignore
  window.firebase = fbase;
}

ReactDOM.render(
  <StoreProvider store={epStore}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <AgTableThemeProvider>
        <App />
      </AgTableThemeProvider>
    </ReactReduxFirebaseProvider>
  </StoreProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

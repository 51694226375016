import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Col, Row } from "antd";
import styled from "styled-components/macro";
import "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import { useGridOptions } from "./gridOptions";
import {
  useAutoSizeAll,
  useExportToCsv,
  useGetAllGridColumns,
  useGetAreAllColumnsVisible,
  useSetQuickFilterText,
} from "./gridApi";
import { useBoolean } from "../../helpers/useBoolean";
import { VisibilityModal } from "./show-hide-columns";
import { useToggle } from "react-use";
import {
  getStateOfGrid,
  MyAgGridReact,
  resetAllFilters,
  restoreStateToGrid,
} from "./my-ag-grid-react";
import { getGridHeight } from "./getGridHeight";
import _ from "lodash";
import { Ribbon } from "./ribbon";
import { CSS_TO_ALWAYS_SHOW_SCROLLBARS, useExtraGridCss } from "./styling";
import { Classes } from "@blueprintjs/core";
import { SearchBoxStore } from "./search-box-store";
import {
  AG_TABLE_RIBBON_HEIGHT_PX,
  AG_TABLE_VISIBILITY_MODAL_LEFT_PADDING_PX,
} from "../../styles/constants";
import { NoData } from "./no-data";
import { useFullScreenModeEnabled } from "../../store/model-misc";
import { useGridBorderRadius } from "./use-grid-border-radius";
import { GridContextProvider, useTableName } from "./my-ag-grid-react-context";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import { AgColDefs, AgGlobals, AgRows, AgState } from "./types";
import { Column } from "@ag-grid-community/core/dist/es6/entities/column";
import { TableRow } from "../../store/table-model-factory";
import {
  RowDataChangedEvent,
  RowDataUpdatedEvent,
} from "@ag-grid-community/core/dist/es6/events";

export type FinalizeRowData = { (rowData: TableRow[]): TableRow[] };
export type FinalizeColDefs = { (colDefs: AgColDefs): AgColDefs };

interface AgTableProps {
  rowData: AgRows;
  columnDefs: AgColDefs;
  onCellClicked?: any;
  height?: string;
  tableName: string;
  tableNameSuffix?: string;
  fullScreenEnabled?: boolean;
  finalizeRowData?: FinalizeRowData;
  finalizeColDefs?: FinalizeColDefs;
  customRibbonLeftElements?: JSX.Element[];
  ribbonTitle?: string | JSX.Element;
  hideEyeball?: boolean;
  hideSearchbox?: boolean;
  hideAutosize?: boolean;
  hideScrollbarWidget?: boolean;
  initialCategoryFilters?: { [colId: string]: any[] };
  categoryFilters?: any;
  setCategoryFilters?: (any) => void;
  pinnedTopRowData?: AgRows;
  pinnedBottomRowData?: AgRows;
  showPinnedRowsInSearch?: boolean;
  factorPinnedRowsIntoCount?: boolean;
  noDataTitle?: string;
}

function AgTable({
  rowData,
  columnDefs,
  onCellClicked,
  height,
  tableName,
  tableNameSuffix,
  fullScreenEnabled,
  finalizeRowData,
  finalizeColDefs,
  customRibbonLeftElements,
  noDataTitle,
  ...restProps
}: AgTableProps) {
  finalizeRowData = finalizeRowData || ((v) => v);
  finalizeColDefs = finalizeColDefs || ((v) => v);
  rowData = finalizeRowData(rowData);
  columnDefs = finalizeColDefs(columnDefs);
  tableName = tableNameSuffix ? `${tableName}__${tableNameSuffix}` : tableName;

  const hasRowsToShow =
    rowData && columnDefs && _.size(rowData) && _.size(columnDefs);

  if (hasRowsToShow) {
    return (
      <SearchBoxStore.Provider>
        <GridContextProvider tableName={tableName}>
          <AgTableInner
            rowData={rowData}
            columnDefs={columnDefs}
            onCellClicked={onCellClicked}
            height={height}
            tableName={tableName}
            fullScreenEnabled={fullScreenEnabled ?? false}
            customRibbonLeftElements={customRibbonLeftElements ?? []}
            {...restProps}
          />
        </GridContextProvider>
      </SearchBoxStore.Provider>
    );
  } else {
    return <NoData noDataTitle={noDataTitle} />;
  }
}

function AgTableInner({
  rowData,
  columnDefs,
  onCellClicked,
  height,
  fullScreenEnabled,
  customRibbonLeftElements,
  ribbonTitle,
  hideEyeball,
  hideSearchbox,
  hideAutosize,
  hideScrollbarWidget,
  initialCategoryFilters,
  categoryFilters,
  setCategoryFilters,
  pinnedTopRowData,
  pinnedBottomRowData,
  showPinnedRowsInSearch = false,
  factorPinnedRowsIntoCount = false,
}: AgTableProps) {
  const g = useGrid({ onCellClicked, factorPinnedRowsIntoCount });

  const extraGridCss = useExtraGridCss(columnDefs);
  const fullScreen = useFullScreenModeEnabled();
  const borderRadius = useGridBorderRadius();
  const tableName = useTableName();

  useEffect(() => {
    if (categoryFilters) {
      if (!_.isEmpty(categoryFilters)) {
        if (
          !_.isEqual(categoryFilters, g.gridRef.current?.api?.getFilterModel())
        ) {
          g.gridRef.current?.api?.setFilterModel(categoryFilters);
        }
      } else {
        resetAllFilters(g.gridRef.current);
      }
    }
  }, [categoryFilters]);

  useEffect(() => {
    if (initialCategoryFilters) {
      g.setCategoryFilters(initialCategoryFilters);
    }
  }, []);

  return (
    <Row
      css={`
        width: 100%;
      `}
    >
      <Col
        data-testid={`${tableName}_table_root`}
        className={`${Classes.ELEVATION_4}`}
        span={g.vmShown ? 18 : 24}
        css={`
          & .ag-center-cols-viewport {
            background-color: #293742;
          }
          border-radius: ${borderRadius};
        `}
      >
        <Row data-testid={`${tableName}_table_content`}>
          <Ribbon
            g={g}
            rowData={rowData}
            colDefs={columnDefs}
            tableName={tableName}
            fullScreenEnabled={fullScreenEnabled}
            customRibbonLeftElements={customRibbonLeftElements}
            ribbonTitle={ribbonTitle}
            hideEyeball={hideEyeball}
            hideSearchbox={hideSearchbox}
            hideAutosize={hideAutosize}
            hideScrollbarWidget={hideScrollbarWidget}
            pinnedTopRowData={pinnedTopRowData}
            pinnedBottomRowData={pinnedBottomRowData}
            factorPinnedRowsIntoCount={factorPinnedRowsIntoCount}
            showPinnedRowsInSearch={showPinnedRowsInSearch}
          />
          <div
            data-testid={`${tableName}_table_content_inner`}
            css={`
              ${extraGridCss}
            `}
          >
            <g.GridWrapper
              height={height}
              fullScreen={fullScreen}
              tableName={tableName}
            >
              <MyAgGridReact
                gridRef={g.gridRef}
                rowData={rowData}
                pinnedTopRowData={pinnedTopRowData}
                pinnedBottomRowData={pinnedBottomRowData}
                columnDefs={columnDefs}
                gridOptions={{
                  ...g.gridOptions,
                  ...(setCategoryFilters
                    ? {
                        onFilterChanged: (event) => {
                          g.gridOptions.onFilterChanged(event);
                          setCategoryFilters(
                            g.gridRef.current?.api?.getFilterModel()
                          );
                        },
                      }
                    : {}),
                }}
              />
            </g.GridWrapper>
          </div>
        </Row>
      </Col>
      {g.vmShown &&
        g.gridIsReady &&
        g.firstDataIsRendered &&
        _.size(g.columns) && (
          <Col
            span={6}
            css={`
              ${fullScreen
                ? `height: 100vh`
                : height
                ? `height: calc(${height} + ${AG_TABLE_RIBBON_HEIGHT_PX})`
                : ``};
              padding-left: ${AG_TABLE_VISIBILITY_MODAL_LEFT_PADDING_PX}px;
            `}
          >
            <VisibilityModal
              columns={g.columns}
              showColumn={g.showColumn}
              hideColumn={g.hideColumn}
            />
          </Col>
        )}
    </Row>
  );
}

const GridInnerWrapper = styled.div`
  height: ${getGridHeight};
  width: 100%;

  transition: height 0.3s ease-out;

  .ag-root[role="grid"] {
    border: none;
  }

  ${({ alwaysShowScrollbars }) =>
    alwaysShowScrollbars && CSS_TO_ALWAYS_SHOW_SCROLLBARS}
`;

function useGrid({
  onCellClicked,
  factorPinnedRowsIntoCount,
}: {
  onCellClicked?: any;
  factorPinnedRowsIntoCount: boolean;
}): AgGlobals {
  const gridRef = useRef<AgGridReact>();

  const [displayedRowCount, setDisplayedRowCount] = useState<number>(null);
  const [localGridState, setLocalGridState] = useState<AgState>({});
  const getLocalGridState = useCallback(() => localGridState, [localGridState]);
  const setLocalGridStateFromActualGridState = useCallback(() => {
    setLocalGridState(getStateOfGrid(gridRef.current));
  }, [setLocalGridState]);

  const getAllGridColumns = useGetAllGridColumns(gridRef);

  // TODO: Just use easy-peasy for all table state
  const [columns, setColumns] = useState<Column[]>([]);
  const refreshColumns = () => {
    setColumns(getAllGridColumns().map((c) => ({ ...c })));
  };
  useEffect(() => {
    if (!_.size(columns)) {
      refreshColumns();
    }
    // eslint-disable-next-line
  }, [_.size(columns)]);

  const gridIsReady = useBoolean(true);
  const firstDataIsRendered = useBoolean(false);
  const exportToCsv = useExportToCsv(gridRef);
  const autoSizeAll = useAutoSizeAll(gridRef);
  const showColumn = useCallback(
    (column: string) => {
      gridRef?.current?.columnApi?.setColumnVisible(column, true);
      refreshColumns();
    },
    // eslint-disable-next-line
    [gridRef, getAllGridColumns]
  );
  const hideColumn = useCallback(
    (column: string) => {
      gridRef?.current?.columnApi?.setColumnVisible(column, false);
      refreshColumns();
    },
    // eslint-disable-next-line
    [gridRef, getAllGridColumns]
  );
  const getAreAllColumnsVisible = useGetAreAllColumnsVisible(gridRef);
  const [vmShown, toggleVmShown] = useToggle(false);
  const setQuickFilterText = useSetQuickFilterText(gridRef);
  const setCategoryFilter = useSetCategoryFilter(gridRef);
  const setCategoryFilters = useSetCategoryFilters(gridRef);

  const alwaysShowScrollbars = useBoolean(false);
  const handleToggleScrollbarsVisible = useCallback(() => {
    setLocalGridStateFromActualGridState();
    alwaysShowScrollbars.toggle();
    // eslint-disable-next-line
  }, [alwaysShowScrollbars.toggle, setLocalGridStateFromActualGridState]);

  useEffect(() => {
    if (_.size(getLocalGridState())) {
      restoreStateToGrid(getLocalGridState(), gridRef.current);
    }
    // eslint-disable-next-line
  }, [getLocalGridState()]);

  const maybeUpdateDisplayedRowCount = useCallback(() => {
    let newDisplayedRowCount = gridRef?.current?.api?.getDisplayedRowCount();
    const pinnedTopRowsCount = gridRef?.current?.api?.getPinnedTopRowCount();
    const pinnedBottomRowsCount =
      gridRef?.current?.api?.getPinnedBottomRowCount();
    if (factorPinnedRowsIntoCount) {
      newDisplayedRowCount =
        newDisplayedRowCount + pinnedTopRowsCount + pinnedBottomRowsCount;
    }

    if (
      newDisplayedRowCount !== undefined &&
      newDisplayedRowCount !== displayedRowCount
    ) {
      setDisplayedRowCount(newDisplayedRowCount);
    }
  }, [displayedRowCount, setDisplayedRowCount, factorPinnedRowsIntoCount]);

  const gridOptions = useGridOptions({
    onCellClicked,
    onRowDataChanged: useCallback(
      (event: RowDataChangedEvent) => {
        maybeUpdateDisplayedRowCount();
      },
      [maybeUpdateDisplayedRowCount]
    ),
    onRowDataUpdated: useCallback(
      (event: RowDataUpdatedEvent) => {
        maybeUpdateDisplayedRowCount();
      },
      [maybeUpdateDisplayedRowCount]
    ),
    //
    onFilterChanged: useCallback(() => {
      maybeUpdateDisplayedRowCount();
      // eslint-disable-next-line
    }, [maybeUpdateDisplayedRowCount]),
    //
    onFirstDataRendered: useCallback(() => {
      firstDataIsRendered.setTrue();

      // gridRef?.current?.api?.setFilterModel({
      //   all_cutoffs_are_recent: {
      //     value: ["true"],
      //   },
      //   max_pct_complete__div__min_cutoff_pct_int: {
      //     filter: 0.9,
      //     filterTo: null,
      //     filterType: "number",
      //     type: "lessThan",
      //   },
      // });
      //
      // gridRef?.current?.api?.onFilterChanged();

      // eslint-disable-next-line
    }, [firstDataIsRendered.setTrue]),
    //
    onColumnVisible: useCallback(() => {
      refreshColumns();
      // eslint-disable-next-line
    }, [setColumns, getAllGridColumns]),
    //
    scrollbarWidth: alwaysShowScrollbars.value ? 8 : undefined,
  });

  useEffect(() => {
    firstDataIsRendered.value && setTimeout(autoSizeAll, 300);
    // eslint-disable-next-line
  }, [firstDataIsRendered.value]);

  const GridWrapper = useCallback(
    ({ children, height, fullScreen, tableName }) => {
      return (
        <div
          data-testid={`${tableName}_grid_wrapper`}
          css={`
            width: 100%;
            height: 100%;
          `}
        >
          <GridInnerWrapper
            data-testid={`${tableName}_grid_wrapper_inner`}
            className="ag-theme-balham-dark"
            alwaysShowScrollbars={alwaysShowScrollbars.value}
            height={height}
            fullScreen={fullScreen}
            tableName={tableName}
          >
            {children}
          </GridInnerWrapper>
        </div>
      );
    },
    // eslint-disable-next-line
    [alwaysShowScrollbars.value]
  );

  return {
    gridRef,
    gridOptions,
    GridWrapper,
    exportToCsv,
    autoSizeAll,
    showColumn,
    hideColumn,
    getAllGridColumns,
    getAreAllColumnsVisible,
    vmShown,
    toggleVmShown,
    VisibilityModal,
    setQuickFilterText,
    toggleScrollbarsVisible: handleToggleScrollbarsVisible,
    gridIsReady,
    firstDataIsRendered,
    mySetStateOfGrid: setLocalGridStateFromActualGridState,
    columns,
    setCategoryFilter,
    setCategoryFilters,
    displayedRowCount,
  };
}

function useSetCategoryFilter(gridRef: MutableRefObject<AgGridReact>) {
  return (colId: string, filterValue: any) => {
    // console.log("useSetCategoryFilter");
    const api = gridRef.current.api;
    api.setFilterModel({
      ...gridRef.current.api.getFilterModel(),
      [colId]: { value: filterValue },
    });
  };
}

function useSetCategoryFilters(gridRef: MutableRefObject<AgGridReact>) {
  return (colId2filterValue) => {
    // console.log("useSetCategoryFilter");
    const api = gridRef.current.api;
    api.setFilterModel({
      ...gridRef.current.api.getFilterModel(),
      ...Object.fromEntries(
        Object.entries(colId2filterValue).map(([colId, filterValue]) => {
          if (filterValue["type"]) {
            return [colId, filterValue];
          }
          return [colId, { value: filterValue }];
        })
      ),
    });
  };
}

export default AgTable;

import { useStoreState } from "../../hooks/ep";
import AgTable from "../../components/AgTable";
import React from "react";
import { useCustomRibbonLeftElements } from "./use-custom-ribbon-left-elements";
import { useColumnDefs } from "./use-column-defs";

export function CmaxUsersTableInner({
  employeeType,
  ...restProps
}): JSX.Element {
  const rowData = useStoreState((s) => s.cmax_user.rowData);
  const columnDefs = useColumnDefs();
  const customRibbonLeftElements = useCustomRibbonLeftElements();
  return (
    <AgTable
      rowData={rowData}
      columnDefs={columnDefs}
      tableName="cmax_users"
      customRibbonLeftElements={customRibbonLeftElements}
      {...restProps}
    />
  );
}

import { useEnsureData } from "../../helpers/use-ensure-data";
import _ from "lodash";
import { Spinner } from "@blueprintjs/core";
import React from "react";
import { CmaxUsersTableInner } from "./cmax-users-table-inner";

export function CmaxUsersTable(props): JSX.Element {
  const loading = [useEnsureData("cmax_user")];
  return _.max(loading) ? <Spinner /> : <CmaxUsersTableInner {...props} />;
}

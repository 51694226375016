import { useCanCreateNewCmaxUser } from "../common/use-can-create-new-cmax-user";
import { CreateNewCmaxUserButton } from "../components/create-new-cmax-user-button";
import React from "react";

export function useCustomRibbonLeftElements() {
  const canCreateNewCmaxUser = useCanCreateNewCmaxUser();
  return canCreateNewCmaxUser
    ? [<CreateNewCmaxUserButton key="button--create-new-cmax-user" />]
    : [];
}

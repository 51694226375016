import { AgColDef, AgColDefs } from "../../components/AgTable/types";
import { useCmaxUserEmailAddressCD } from "../columns/email-address";
import { useCmaxUserFullNameCD } from "../columns/full-name";
import { useCmaxUserLastVisitedTimestampCD } from "../columns/latest-flask-request-timestamp";
import { useCmaxUserNumDaysVisitedInPastMonthCD } from "../columns/num-days-visited-in-past-month";
import { useIsActiveColDef } from "../columns/use-is-active-col-def";
import { useFirstNameColDef } from "../columns/use-first-name-col-def";
import { useLastNameColDef } from "../columns/use-last-name-col-def";
import { useTimezoneColDef } from "../columns/use-timezone-col-def";
import { useRoleColDef } from "../columns/use-role-col-def";

export function useColumnDefs(): AgColDefs {
  let ret = [
    useCmaxUserFullNameCD({ pinned: "left" }),
    useCmaxUserEmailAddressCD(),
    useCmaxUserLastVisitedTimestampCD(),
    useCmaxUserNumDaysVisitedInPastMonthCD(),
    useIsActiveColDef(),
    useRoleColDef(),
    useFirstNameColDef(),
    useLastNameColDef(),
    useTimezoneColDef(),
  ] as AgColDef[];

  return ret.filter((cd) => {
    // @ts-ignore
    return cd.__authorized ?? true;
  });
}

import React from "react";
import { inferMailtoLinks } from "../linked-text/cell-renderer";

export function UrlCellRenderer({ value: url }) {
  // noinspection JSConstructorReturnsPrimitive
  return !url ? null : (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={inferMailtoLinks(url)} target="_blank">
      Link
    </a>
  );
}

import _ from "lodash";
import moment from "moment-timezone";
import { getFormatter } from "../../entities/helpers/relation-agg-cr";

export type Params<T> = T | { value: T };

const nfFrenchFloat = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
const nfMoney_ = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
export const nfMoney = {
  format: (n: number): string => nfMoney_.format(n),
  // format: (n: number): string => "$\u00a0" + nfMoney_.format(n).slice(1),
};

export const nfInt = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
const FALSEY_VALUES = new Set([
  false,
  "false",
  "False",
  "No",
  "no",
  0,
  0.0,
  -0,
]);
const TRUTHY_VALUES = new Set([true, "true", "True", "Yes", "yes", 1, 1.0]);

export function dateVF(params) {
  const value = _.isString(params) ? params : params.value;
  let ret = null;
  if (value) {
    // console.log("dateVF value:", value);
    const dateString = new Date(value).toLocaleDateString([], {
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone: "UTC",
    });
    // console.log("dateVF dateString:", dateString);
    if (dateString !== "Invalid Date") {
      ret = dateString;
    }
  }
  return ret;
}

export function timestampVF({ value, colDef }) {
  let ret = null;
  let fmt;
  if (value) {
    if (_.isString(value)) {
      ret = moment(value);
    } else if (_.isNumber(value)) {
      ret = moment(value);
    } else if (_.isDate(value)) {
      ret = moment(value);
    }
  }
  if (ret !== null) {
    if (colDef.__timezone) {
      ret = ret.tz(colDef.__timezone);
      fmt = "ddd ll LT (z)";
    } else {
      ret = ret.utc(false);
      fmt = "ddd ll LT";
    }
    ret = ret.format(fmt);
  }
  return ret;
}

export function booleanVF(params: Params<boolean>): string {
  const value = typeof params === "object" ? params.value : params;
  if (isna(value)) {
    return "N/A";
  } else if (FALSEY_VALUES.has(value)) {
    return "";
  } else if (TRUTHY_VALUES.has(value)) {
    return "\u2713";
  } else {
    throw Error(`booleanVF cannot format params with value=${value}`);
  }
}

export function percentageVF(params: Params<number>): string {
  let ret = integerVF(params);
  return ret ? `${ret}%` : "";
}

export function riskPercentageChangeVF(params: Params<number>): string {
  const value = params2number(params);
  if (isna(value)) {
    return "N/A";
  } else {
    const prefix = value === 0 ? "" : value < 0 ? "-" : "+";
    return `${prefix}${floatVF(Math.abs(value))}%`;
  }
}

export function floatVF(params: Params<number>): string {
  const value = params2number(params);
  // @ts-ignore
  const nDecimals = params.colDef?.__nDecimals ?? 2;

  const fmtr =
    nDecimals === 2
      ? nfFrenchFloat
      : new Intl.NumberFormat("en-US", {
          minimumFractionDigits: nDecimals,
          maximumFractionDigits: nDecimals,
        });

  return fmtr.format(value);
}

export function moneyVF(params: Params<number>): string {
  if (_.isNil(params)) {
    return "";
  }
  // @ts-ignore
  const { colDef = {}, node, data } = _.isNumber(params) ? {} : params;

  // @ts-ignore
  const { __renderZerosAsEmpty = false } = colDef;

  const columnTypeForRow =
    data?.__columnTypesForRow?.[colDef?.colId ?? colDef?.field] ??
    "moneyColumn";
  if (node && node.isRowPinned() && columnTypeForRow !== "moneyColumn") {
    const fmtr = getFormatter(columnTypeForRow);
    return fmtr(params);
  } else {
    const value = params2number(params);
    if (isna(value) || (value === 0 && __renderZerosAsEmpty)) {
      return "";
    } else {
      return nfMoney.format(value);
      // const prefix = value < 0 ? "-" : "";
      // // @ts-ignore
      // return prefix + __currencySymbol + floatVF(Math.abs(value));
    }
  }
}

export function integerVF(params: Params<number>): string {
  const value = params2number(params);
  const valueUnknown = isna(value);
  return valueUnknown ? "" : nfInt.format(value);
}

export function integerIdentifierVF(params: Params<number>): string {
  const value = params2number(params);
  const valueUnknown = isna(value);
  return valueUnknown ? "" : value.toString();
}

export function params2number(params: Params<number>): number {
  const ret = isna(params) || _.isNumber(params) ? params : params.value;
  return ret as number;
}

export function isna(v: any): boolean {
  return _.isNull(v) || _.isUndefined(v) || _.isNaN(v);
}

import { makeAuthorizedGetRequestToBackend3, makeUrl } from "./backendApi";
import { useAsync } from "react-use";
import { useMe } from "../hooks/useMe";

export const DEFAULT_SHOULD_FETCH = true;

export function useEndpoint<T>(
  endpoint,
  shouldFetch = DEFAULT_SHOULD_FETCH,
  queryParams = {}
): { loading: boolean; data: undefined | T } {
  const me = useMe();
  const notReady = me.loading;
  const urlWithParams = makeUrl(endpoint, queryParams);
  const fn = async () => {
    if (notReady || !shouldFetch) return {};
    const data = await makeAuthorizedGetRequestToBackend3({
      url: urlWithParams,
    });
    return data;
  };
  const { loading, value: data } = useAsync(fn, [urlWithParams, me.loading]);
  if (notReady) return { loading: true, data: undefined };
  return { loading, data };
}

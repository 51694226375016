import { thunk } from "easy-peasy";
import {
  DEFAULT_RESPONSE_FORMAT,
  makeAuthorizedGetRequestToBackend3,
  makeUrl,
} from "../helpers/backendApi";
import { toaster } from "../toaster";

export const thunks = (modelSliceName, getPatchPayload, getPostPayload) => ({
  handleFetchInitialData: thunk(async (actions, payload, { getState }) => {
    const {
      INITIAL_DATA_ENDPOINT,
      INITIAL_DATA_FORMAT = DEFAULT_RESPONSE_FORMAT,
    } = getState();
    const data = await makeAuthorizedGetRequestToBackend3({
      url: makeUrl(INITIAL_DATA_ENDPOINT, {
        responseFormat: INITIAL_DATA_FORMAT,
      }),
    });
    actions.receiveInitialData(data);
    actions.markInitialDataReceived();
  }),
  maybeHandleFetchInitialData: thunk(
    async (actions, payload, { getState, dispatch }) => {
      const { initialDataLoading, initialDataReceived, NAME } = getState();
      if (!initialDataLoading && !initialDataReceived) {
        actions.markInitialDataLoading();
        try {
          if (!initialDataReceived) {
            await dispatch[NAME].handleFetchInitialData();
          }
        } catch (e) {
          if (payload && payload.verbose) {
            toaster.error(
              "Sorry - something went wrong. Try refreshing this page. If this message appears again, please email team@saxecap.com.",
              5
            );
          }
          throw e;
        } finally {
          actions.markInitialDataNotLoading();
        }
      }
    }
  ),
});

import React, { useState } from "react";
import "styled-components/macro";
import { FormWrapper } from "./form-wrapper";
import { Drawer } from "@blueprintjs/core";

import { DRAWER_WIDTH } from "../../styles/constants";
import { useDrawerForm } from "../../store/model-drawer-form";

export function DrawerFormWrapper() {
  const { isOpen } = useDrawerForm();
  return isOpen && <DrawerForm />;
}

function DrawerForm() {
  return (
    <MyDrawer>
      <FormWrapper>
        <FormContent />
      </FormWrapper>
    </MyDrawer>
  );
}

function MyDrawer({ children }) {
  const { isOpen, close, title } = useDrawerForm();
  return (
    <Drawer
      canEscapeKeyClose={false}
      isCloseButtonShown={true}
      isOpen={isOpen}
      onClose={close}
      className="bp3-dark"
      size={DRAWER_WIDTH}
      title={title}
    >
      <div
        css={`
          padding: 24px;
          overflow-y: auto;
          height: 100%;
        `}
      >
        {children}
      </div>
    </Drawer>
  );
}

function FormContent() {
  const { ContentComponent } = useDrawerForm();
  const [activeField, setActiveField] = useState(null);

  return (
    <ContentComponent
      activeField={activeField}
      setActiveField={setActiveField}
    />
  );
}

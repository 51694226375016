import { useMeData } from "../../hooks/useMe";
import { UserRole } from "../../store/model-me";
import { useMemo } from "react";
import { ALL_ENTITY_NAMES, EntityName, isAuthorizedForEntity } from "./core";
import { isAuthorizedForLevelReads, ReadLevel } from "./level-reads-auth";
import { isAuthorizedForLevelWrites, WriteLevel } from "./level-writes-auth";

export function useMyRole(): UserRole {
  return useMeData().role;
}
export function useMyEmail(): string {
  return useMeData().email;
}
export function useMyTimezone(): string {
  return useMeData().timezone;
}

export function useAuthorizedForEntity(
  entityName: EntityName,
  isBackendRequest: boolean = false
): boolean {
  const myRole = useMyRole();
  return isAuthorizedForEntity(entityName, myRole, isBackendRequest);
}

export function useAuthorizedEntityNames(): EntityName[] {
  const myRole = useMyRole();
  return useMemo(
    () => ALL_ENTITY_NAMES.filter((v) => isAuthorizedForEntity(v, myRole)),
    [myRole]
  );
}

export function useCanDoLevelReads(level: ReadLevel): boolean {
  const myRole = useMyRole();
  const myEmail = useMyEmail();
  return isAuthorizedForLevelReads(level, myRole, myEmail);
}

export function useCanDoLevelWrites(level: WriteLevel): boolean {
  const myRole = useMyRole();
  const myEmail = useMyEmail();
  return isAuthorizedForLevelWrites(level, myRole, myEmail);
}

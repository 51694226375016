import { AgColDef } from "../../components/AgTable/types";

export function useCmaxUserEmailAddressCD(overrides?: AgColDef): AgColDef {
  return getCmaxUserEmailAddressCD(overrides);
}
export function getCmaxUserEmailAddressCD(overrides?: AgColDef): AgColDef {
  return {
    headerName: "Email",
    field: "email",
    type: "textColumn",
    headerTooltip: "The Customax user's email address",
    ...(overrides ?? {}),
  };
}

import { Button } from "@blueprintjs/core";
import React, { useRef } from "react";
import "styled-components/macro";
import { message, Tooltip } from "antd";
import {
  useDataIsSubmittable,
  useDrawerForm,
  useFieldName2Value,
  useFormErrorMessage,
} from "../../store/model-drawer-form";

export function FormWrapper({ children }) {
  const formErrorMessage = useFormErrorMessage();
  const isSubmitted = useRef(false);
  const onSubmit = useOnSubmit(isSubmitted);
  const readyToSubmit = !formErrorMessage;
  const notReadyToSubmit = !readyToSubmit;

  return (
    <form
      onSubmit={onSubmit}
      css={`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        & > * {
          margin-bottom: 48px !important;
        }
        & > *:last-child {
          margin-bottom: unset !important;
        }
      `}
    >
      {children}
      <Tooltip
        placement="bottom"
        title={formErrorMessage && <span>{formErrorMessage}</span>}
        mouseEnterDelay={0.1}
        mouseLeaveDelay={0.1}
      >
        <span>
          <Button
            css={`
              margin-top: 100px;
            `}
            fill
            type="submit"
            intent="primary"
            disabled={notReadyToSubmit || isSubmitted.current}
          >
            Submit
          </Button>
        </span>
      </Tooltip>
    </form>
  );
}

function useOnSubmit(isSubmitted: React.MutableRefObject<boolean>) {
  const dataIsSubmittable = useDataIsSubmittable();
  const fieldName2Value = useFieldName2Value();
  const { close, handleSubmit } = useDrawerForm();

  return async (ev) => {
    ev.preventDefault();
    if (!dataIsSubmittable) {
      message.error("Please fill out all form fields before submitting");
    } else if (isSubmitted.current) {
      // No double-submissions!
      console.error("Already submited");
    } else {
      try {
        isSubmitted.current = true;
        await handleSubmit(fieldName2Value);
        close();
      } catch (err) {
        isSubmitted.current = false;
      }
    }
  };
}

import React, { useCallback } from "react";
import { StyledFormGroup } from "../forms-stuff/styled-form-group";
import { Classes, InputGroup } from "@blueprintjs/core";
import "styled-components/macro";

export function EmailInput({
  email,
  setEmail,
  activeField,
  setActiveField,
  helperText,
  placeholder,
  dataTestId,
}) {
  const onEmailInputChange = useCallback(
    (ev) => {
      ev.preventDefault();
      setEmail(ev.target.value);
    },
    [setEmail]
  );

  return (
    <StyledFormGroup
      labelFor="email"
      label="Email"
      helperText={helperText}
      activeField={activeField}
    >
      <InputGroup
        data-testid={dataTestId}
        name="email"
        className={Classes.ELEVATION_2}
        type="email"
        fill={false}
        large={true}
        leftIcon="envelope"
        value={email}
        onChange={onEmailInputChange}
        placeholder={placeholder}
        onFocus={() => setActiveField("email")}
      />
    </StyledFormGroup>
  );
}

const VALID_EMAIL_PATT = /^.+?@.+?\.\w{2,3}$/;

export function isValidEmail(email) {
  return VALID_EMAIL_PATT.test(email);
}

import React from "react";
import AppWrapper from "./components/wrapper/AppWrapper";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Switch } from "react-router";
import { withFbaseAuthentication } from "./helpers/fbase";
import { useTitle } from "react-use";
import { useMe } from "./hooks/useMe";
import { AllRoutes } from "./pages-routes";
import { DOCUMENT_TITLE_AND_HEADER_TEXT } from "./styles/constants";
import { Spinner } from "@blueprintjs/core";
import { useSmartlook } from "./hooks/use-smartlook";
import { useFirebaseAnalytics } from "./hooks/use-firebase-analytics";
import {
  QueryParamProvider,
  ExtendedStringifyOptions,
  transformSearchStringJsonSafe,
} from "use-query-params";

const queryStringifyOptions: ExtendedStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe,
};

function App() {
  useTitle(DOCUMENT_TITLE_AND_HEADER_TEXT);

  const me = useMe();

  useFirebaseAnalytics(me.initialData);
  useSmartlook(me.initialData);

  if (me.loading) {
    return (
      <AppWrapper hideLeftNav={true}>
        <Spinner />
      </AppWrapper>
    );
  } else {
    return (
      <div className="bp3-dark">
        <Router>
          <QueryParamProvider
            ReactRouterRoute={Route}
            // stringifyOptions={queryStringifyOptions}
          >
            <Switch>{AllRoutes}</Switch>
          </QueryParamProvider>
        </Router>
      </div>
    );
  }
}

export default withFbaseAuthentication(App);

import { AgColDef } from "../../components/AgTable/types";

export function useCmaxUserFullNameCD(overrides?: AgColDef): AgColDef {
  return getCmaxUserFullNameCD(overrides);
}
export function getCmaxUserFullNameCD(overrides?: AgColDef): AgColDef {
  return {
    headerName: "Name",
    field: "full_name",
    type: "textColumn",
    headerTooltip: "The Customax user's full name",
    ...(overrides ?? {}),
  };
}

import { Action, action, ActionCreator, ActionOn, actionOn } from "easy-peasy";
import { StoreModel } from "./model";
import queryString from "query-string";
import { useStoreActions, useStoreState } from "../hooks/ep";
import { useCallback } from "react";

type EntityId = string;
type EntityType = "customer" | "user"; //| 'zoho_product' | 'product' | 'service' | 'part' | 'program'
const DEFAULT_SELECTED_ENTITY_IDS = { customer: "", user: "" };

export interface EntitySelectionModel {
  NAME: string;
  selectedEntityIds: { [entityType in EntityType]: EntityId };
  selectEntityId: Action<EntitySelectionModel, [EntityType, EntityId]>;
  onLogin: ActionOn<EntitySelectionModel, StoreModel>;
  onLogout: ActionOn<EntitySelectionModel, StoreModel>;
}

export const entitySelectionModel: EntitySelectionModel = {
  // NOTE: Customer "IDs" are actually customer names
  // NOTE: User "IDs" are actually user emails
  NAME: "entitySelection",
  selectedEntityIds: DEFAULT_SELECTED_ENTITY_IDS,
  selectEntityId: action((state, [newEntityType, newEntityId]) => {
    state.selectedEntityIds[newEntityType] = newEntityId;
  }),
  onLogin: actionOn(
    (__, { me }) => me.receiveInitialData,
    (state, target) => {
      state.selectedEntityIds = {
        ...DEFAULT_SELECTED_ENTITY_IDS,
        ...queryString.parse(window.location.search),
      };
    }
  ),
  onLogout: actionOn(
    (__, { me }) => me.resetData,
    (state, target) => {
      state.selectedEntityIds = DEFAULT_SELECTED_ENTITY_IDS;
    }
  ),
};

type SelectEntityId = ActionCreator<[EntityType, string]>;
type SelectEntityIdOfType = { (entityId: EntityId): void };

export function useSelectedEntityId(entityType: EntityType): EntityId {
  return useStoreState((s) => s.entitySelection.selectedEntityIds[entityType]);
}

export function useSelectEntityId(
  entityType: EntityType
): SelectEntityIdOfType {
  const selectEntityId: SelectEntityId = useStoreActions(
    (s) => s.entitySelection.selectEntityId
  );
  return useCallback(
    (entityId: EntityId) => selectEntityId([entityType, entityId]),
    [entityType, selectEntityId]
  );
}

export function useEntitySelection(
  entityType: EntityType
): [EntityId, SelectEntityIdOfType] {
  return [useSelectedEntityId(entityType), useSelectEntityId(entityType)];
}

import { AgColDef } from "../../components/AgTable/types";

export function useCmaxUserNumDaysVisitedInPastMonthCD(
  overrides?: AgColDef
): AgColDef {
  return getCmaxUserNumDaysVisitedInPastMonthCD(overrides);
}
export function getCmaxUserNumDaysVisitedInPastMonthCD(
  overrides?: AgColDef
): AgColDef {
  return {
    headerName: "# Days Visited (Past Month)",
    field: "num_active_days_in_past_30_days",
    type: "integerColumn",
    headerTooltip:
      "The number of days within the past 30 days that the Customax user has visited Customax at least once",
    ...(overrides ?? {}),
  };
}

import { useMe } from "../../hooks/useMe";
import React from "react";
import _ from "lodash";
import { Breadcrumbs } from "@blueprintjs/core";
import { BREADCRUMBS, Routes } from "../../pages-routes";

export function MyBreadcrumbs() {
  const me = useMe();

  return me.loading || !me.success ? null : <MyBreadcrumbsInner />;
}

function MyBreadcrumbsInner() {
  const pathname = window.location.pathname;
  const locationPathnameParts = pathname.split("/");
  // const pfx = locationPathnameParts[1];

  const items = BREADCRUMBS.filter((el) => {
    const elPathnameParts = el.href.split("/");
    let shouldKeep = true;
    _.range(locationPathnameParts.length).forEach((i) => {
      if (shouldKeep !== false && i < elPathnameParts.length) {
        shouldKeep = locationPathnameParts[i] === elPathnameParts[i];
      }
    });
    return shouldKeep;
  });

  return (
    <div
      css={`
        //position: absolute;
        //margin-top: 4px;
        //margin-left: 12px;
        display: flex;
        align-items: center;
        width: fit-content;
      `}
    >
      <Breadcrumbs
        // currentBreadcrumbRenderer={renderCurrentBreadcrumb}
        items={items}
      />
    </div>
  );
}

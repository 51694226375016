import { assertType } from "../../helpers/assertions";
import { UserRole } from "../../store/model-me";
import { CmaxUsersTable } from "../../cmax-users/table/cmax-users-table";

interface EntityTableComponentType {
  (props: any): JSX.Element;
}

export type EntityName = "cmax_user";

export const ALL_ENTITY_NAMES: EntityName[] = ["cmax_user"];

export function isAuthorizedForEntity(
  entityName: EntityName,
  myRole: UserRole,
  isBackendRequest: boolean = false
): boolean {
  return true;
}

const ENTITY_TABLE_COMPONENTS: {
  [name in EntityName]: EntityTableComponentType;
} = {
  cmax_user: CmaxUsersTable,
};

export function getEntityTableComponent(entityName: EntityName) {
  assertType<EntityName>(entityName);
  return ENTITY_TABLE_COMPONENTS[entityName];
}

import React, { useCallback, useMemo } from "react";
import { Checkbox } from "antd";
import styled from "styled-components/macro";
import { Button, ButtonGroup } from "@blueprintjs/core";

export const StyledCheckboxGroup = styled(Checkbox.Group)`
  display: flex !important;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  max-height: ${(props) => props.maxheight || "300px"};
  // overflow: scroll;

  & .ant-checkbox-wrapper {
    margin: 0 !important;
  }
`;

export function CheckboxGroupActions({ onChange }) {
  const clear = useCallback(() => onChange([]), [onChange]);
  const selectAll = useCallback(() => onChange(null), [onChange]);
  return (
    <ButtonGroup
      css={`
        margin-top: 8px;
        margin-bottom: 8px;
      `}
    >
      <Button intent="primary" small onClick={clear}>
        Clear
      </Button>
      <Button intent="primary" small onClick={selectAll}>
        Select All
      </Button>
    </ButtonGroup>
  );
}

export function MyCheckboxGroup({ onChange, ...restProps }) {
  return (
    <>
      <CheckboxGroupActions onChange={onChange} />
      <StyledCheckboxGroup onChange={onChange} {...restProps} />
    </>
  );
}

export function MyCheckbox({ value, title, marginBottom }) {
  return useMemo(() => {
    return (
      <Checkbox
        key={value}
        value={value}
        css={`
          margin: 0 0 ${marginBottom ?? "8px"} 0 !important;
        `}
      >
        {title}
      </Checkbox>
    );
  }, [value, title]);
}

import { AgColDef } from "../../components/AgTable/types";
import { useCanDoLevelWrites } from "../../entities/helpers/authorization";
import { useStoreActions } from "../../hooks/ep";
import _ from "lodash";
import { toaster } from "../../toaster";
import { CellValueChangedEvent } from "@ag-grid-community/core/dist/es6/events";

export function useFirstNameColDef(): AgColDef {
  const canDoLevel2Writes = useCanDoLevelWrites(2);
  const handlePatchUser = useStoreActions(
    (a) => a.cmax_user.handlePatchCmaxUser
  );
  let colDef = {
    headerName: "First",
    field: "first_name",
    type: "textColumn",
  };
  if (canDoLevel2Writes) {
    colDef = {
      ...colDef,
      editable: (params) => true,
      cellClass: (params) => {
        return [
          "bp3-minimal",
          "bp3-tag",
          "bp3-interactive",
          // "bp3-round",
          "bp3-small",
        ];
      },
      valueParser: (params) => _.trim(params.newValue),
      valueSetter: (params) => {
        if (params.newValue) {
          params.data.first_name = params.newValue;
          return true;
        } else {
          toaster.error("Please submit a name");
          return false;
        }
      },
      onCellValueChanged: async (ev: CellValueChangedEvent) => {
        await handlePatchUser([
          ev.data,
          {
            first_name: ev.newValue,
          },
        ]);
      },
    };
  }
  return colDef;
}

import { useParams } from "react-router";

export function useInsideReactRouterSwitch(): boolean {
  try {
    useParams();
    return true;
  } catch (e) {
    return false;
  }
}

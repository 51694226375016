import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "core-js/features/array/find";
import "core-js/features/array/includes";
import "core-js/features/object/entries";
import "core-js/features/number/is-nan";

import "easy-peasy/proxy-polyfill";
import "easy-peasy/map-set-support";

///////////////////////////////////
// For ie11:                     //
//     Element.prototype.matches //
//     Element.prototype.closest //
//     Element.prototype.remove  //
///////////////////////////////////
if (typeof window !== "undefined" && typeof document !== "undefined") {
  // we're in browser
  // tslint:disable-next-line:no-var-requires
  require("dom4"); // only import actual dom4 if we're in the browser (not server-compatible)
  // we'll still need dom4 types for the TypeScript to compile, these are included in package.json
}

import { useStoreActions, useStoreState } from "../hooks/ep";
import { useAsync } from "react-use";
import { BackendConnectedModelSliceName } from "../store/model";
import _ from "lodash";
import { useCallback } from "react";
// import { useAuthorizedForEntity } from "../components/entities/helpers/authorization";
// import { EntityName } from "../components/entities/helpers/core";

export function useEnsureData(
  modelSlice: BackendConnectedModelSliceName
): boolean {
  const isAuthorized = true;
  // const isAuthorized = useAuthorizedForEntity(modelSlice as EntityName, true);

  const initialDataReceived = useStoreState(
    (s) => s[modelSlice].initialDataReceived
  );
  const initialDataLoading = useStoreState(
    (s) => s[modelSlice].initialDataLoading
  );

  const fetcher = useStoreActions(
    (a) => a[modelSlice].maybeHandleFetchInitialData
  );

  const handleFetch = useCallback(async () => {
    if (isAuthorized && !initialDataReceived) {
      await fetcher(null);
    }
  }, [fetcher]);

  const { loading } = useAsync(
    handleFetch
    // [initialDataReceived]
  );

  return loading || initialDataLoading;
}

export function useEnsureDataMulti(
  modelSlices: BackendConnectedModelSliceName[]
): boolean {
  const loadings = [];
  for (let i = 0; i < modelSlices.length; i++) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    loadings.push(useEnsureData(modelSlices[i]));
  }
  return _.max(loadings);
}

import {
  AG_TABLE_RIBBON_HEIGHT_PX,
  LAYOUT_CONTENT_VERTICAL_PADDING,
  LAYOUT_HEADER_HEIGHT_PX,
} from "../../styles/constants";

export const getGridHeight = ({ height, fullScreen, tableName }) => {
  if (fullScreen) {
    return `calc(100vh - ${AG_TABLE_RIBBON_HEIGHT_PX})`;
  } else if (height) {
    return height;
  } else {
    return `calc(100vh - ${AG_TABLE_RIBBON_HEIGHT_PX} - ${LAYOUT_HEADER_HEIGHT_PX} - 2 * ${LAYOUT_CONTENT_VERTICAL_PADDING})`;
  }
};

export const getGridHeightWithRibbon = () =>
  `calc(100vh - ${LAYOUT_HEADER_HEIGHT_PX} - 2 * ${LAYOUT_CONTENT_VERTICAL_PADDING})`;

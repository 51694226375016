const DOCUMENT_TITLE_AND_HEADER_TEXT = "Customax";
const n = 8;

const LAYOUT_HEADER_TEXT_MARGIN_LEFT = "-20px";
const LAYOUT_HEADER_HEIGHT_M = 8;
const LAYOUT_HEADER_HEIGHT_PX = `${LAYOUT_HEADER_HEIGHT_M * n}px`;
const LAYOUT_HEADER_TEXT_FONT_SIZE = "18px";

const LAYOUT_LEFTNAV_WIDTH_PX = LAYOUT_HEADER_HEIGHT_PX;
const LAYOUT_LEFTNAV_ITEM_HEIGHT = `${(LAYOUT_HEADER_HEIGHT_M / 2 + 1) * n}px`;

const LAYOUT_CONTENT_HORIZONTAL_PADDING = `${5 * n}px`;
const LAYOUT_CONTENT_VERTICAL_PADDING = `${5 * n}px`;

const AG_TABLE_RIBBON_HEIGHT = 6 * n;
const AG_TABLE_RIBBON_HEIGHT_PX = `${AG_TABLE_RIBBON_HEIGHT}px`;
const AG_TABLE_RIBBON_HORIZONTAL_GUTTER = 1 * n;
const AG_TABLE_RIBBON_BUTTON_TYPE = null;
const AG_TABLE_RIBBON_BUTTON_IS_GHOST = true;
const AG_TABLE_RIBBON_BUTTON_SIZE = "default";
const AG_TABLE_RIBBON_BUTTON_TOOLTIP_DELAY = 0.6;
const AG_TABLE_VISIBILITY_MODAL_LEFT_PADDING_PX = 5 * n;

const DRAWER_WIDTH = 600;
const DRAWER_WIDTH_PX = `${DRAWER_WIDTH}px`;

export {
  AG_TABLE_RIBBON_HEIGHT,
  AG_TABLE_RIBBON_HEIGHT_PX,
  AG_TABLE_RIBBON_HORIZONTAL_GUTTER,
  LAYOUT_CONTENT_HORIZONTAL_PADDING,
  LAYOUT_CONTENT_VERTICAL_PADDING,
  LAYOUT_HEADER_TEXT_FONT_SIZE,
  DOCUMENT_TITLE_AND_HEADER_TEXT,
  LAYOUT_HEADER_HEIGHT_PX,
  LAYOUT_HEADER_TEXT_MARGIN_LEFT,
  LAYOUT_LEFTNAV_WIDTH_PX,
  LAYOUT_LEFTNAV_ITEM_HEIGHT,
  AG_TABLE_RIBBON_BUTTON_TYPE,
  AG_TABLE_RIBBON_BUTTON_IS_GHOST,
  AG_TABLE_RIBBON_BUTTON_SIZE,
  AG_TABLE_RIBBON_BUTTON_TOOLTIP_DELAY,
  AG_TABLE_VISIBILITY_MODAL_LEFT_PADDING_PX,
  DRAWER_WIDTH,
  DRAWER_WIDTH_PX,
};

import { AgColDef } from "../../components/AgTable/types";
import { useCanDoLevelWrites } from "../../entities/helpers/authorization";
import { useStoreActions } from "../../hooks/ep";
import _ from "lodash";
import { VALID_TIMEZONES } from "../../common/valid-timezones";
import { toaster } from "../../toaster";
import { CellValueChangedEvent } from "@ag-grid-community/core/dist/es6/events";

export function useTimezoneColDef(): AgColDef {
  const canDoLevel2Writes = useCanDoLevelWrites(2);
  const handlePatchUser = useStoreActions(
    (a) => a.cmax_user.handlePatchCmaxUser
  );
  let colDef = {
    headerName: "Timezone",
    field: "timezone",
    type: "categoryColumn",
  };
  if (canDoLevel2Writes) {
    colDef = {
      ...colDef,
      editable: (params) => true,
      cellClass: (params) => {
        return [
          "bp3-minimal",
          "bp3-tag",
          "bp3-interactive",
          // "bp3-round",
          "bp3-small",
        ];
      },
      valueParser: (params) => _.trim(params.newValue),
      valueSetter: (params) => {
        const isValid = VALID_TIMEZONES.includes(params.newValue);

        if (isValid) {
          params.data.timezone = params.newValue;
          return true;
        } else {
          toaster.error(
            `Please choose one of the ${
              VALID_TIMEZONES.length
            } valid timezones: ${VALID_TIMEZONES.join(", ")}`
          );
          return false;
        }
      },
      onCellValueChanged: async (ev: CellValueChangedEvent) => {
        await handlePatchUser([
          ev.data,
          {
            timezone: ev.newValue,
          },
        ]);
      },
    };
  }
  return colDef;
}

import React, { ReactNode, useMemo } from "react";
import { ICellRendererParams } from "@ag-grid-community/core/dist/es6/rendering/cellRenderers/iCellRenderer";
import {
  Intent,
  Popover,
  PopoverInteractionKind,
  PopoverPosition,
  Position,
  Tag,
} from "@blueprintjs/core";
import "styled-components/macro";
import {
  getField,
  TableRow,
  TableRowId,
} from "../../store/table-model-factory";
import { ValueGetterParams } from "@ag-grid-community/core/dist/es6/entities/colDef";
import { AgColDef } from "../../components/AgTable/types";
import _ from "lodash";
import { useStoreState } from "../../hooks/ep";
import { DescriptionItemConfig } from "../../components/flat-descriptions";
import { HierarchicalRelatedEntitiesTag } from "../../components/hier-rel-ent-counts-tag";
import { EntityName, getEntityTableComponent } from "./core";
import {
  booleanVF,
  dateVF,
  floatVF,
  integerVF,
  moneyVF,
  Params,
  percentageVF,
  timestampVF,
} from "../../components/AgTable/value-formatting";

interface ShouldRenderTag {
  (value: any): boolean;
}

export interface MyCellRendererParams {
  crp: {
    itemConfig?: DescriptionItemConfig;
    shouldRenderTag?: ShouldRenderTag;
    baseEntityName?: EntityName;
    entityName: EntityName;
    getEntityIds: { (props: ICellRendererParams): TableRowId[] };
    getTagIntent?: any;
  };
}

export interface RelatedEntitiesProps {
  baseEntityName?: EntityName;
  entityName: EntityName;
  entityIds: TableRowId[] | Set<TableRowId>;
  finalizeColDefs?: any;
  finalizeRowData?: any;
  foreignField?: string | string[];
  type?: "float" | "money" | "integer";
  intent?: Intent;
}

export interface RelatedEntitiesTagProps extends RelatedEntitiesProps {
  position?: PopoverPosition;
  children: ReactNode | JSX.Element;
}

export function RelationshipAggregationCellRenderer(
  props: ICellRendererParams & MyCellRendererParams
): JSX.Element {
  const position = PopoverPosition.BOTTOM; // PopoverPosition.AUTO_START is also a good option
  const itemConfig = props.crp?.itemConfig;
  if (itemConfig) {
    const visible = itemConfig.getVisible
      ? itemConfig.getVisible(props.data)
      : true;
    return !visible ? (
      props.valueFormatted
    ) : (
      <HierarchicalRelatedEntitiesTag
        itemConfig={itemConfig}
        data={props.data}
        position={position}
      >
        {props.valueFormatted}
      </HierarchicalRelatedEntitiesTag>
    );
  } else {
    const visible = props.crp.shouldRenderTag
      ? props.crp.shouldRenderTag(props)
      : true;
    return !visible ? (
      props.valueFormatted
    ) : (
      <RelatedEntitiesTag
        baseEntityName={props.crp.baseEntityName}
        entityName={props.crp.entityName}
        intent={
          props.crp.getTagIntent
            ? props.crp.getTagIntent(props.data)
            : Intent.PRIMARY
        }
        entityIds={props.crp.getEntityIds(props)}
        position={position}
      >
        {props.valueFormatted}
      </RelatedEntitiesTag>
    );
  }
}

function maybeExtractEntityIds(
  value: number[] | string | { entityIds: number[] | string }
): number[] {
  if (_.isString(value)) {
    if (value === "") {
      return [];
    } else {
      return value.split(",").map((v) => +v);
    }
  } else if (_.isArray(value)) {
    return value;
  } else {
    return maybeExtractEntityIds(value.entityIds);
  }
}

export function getHierarchicalRelatedEntityCountsColDef(
  itemConfig: DescriptionItemConfig
): AgColDef {
  const { label, field } = itemConfig;

  return {
    headerName: label,
    field,
    valueGetter: (params: ValueGetterParams): number =>
      maybeExtractEntityIds(params.data[field]).length,
    type: "integerColumn",
    cellRenderer: "relationshipAggregationCellRenderer",
    cellRendererParams: {
      crp: {
        itemConfig,
      },
    },
  };
}

export function getCountColDef({
  baseEntityName,
  headerName,
  entityName,
  field,
  actualField = null,
  ...restProps
}: {
  headerName: string;
  baseEntityName: EntityName;
  entityName: EntityName;
  field: string;
  actualField?: string;
}): AgColDef {
  return {
    headerName,
    field: actualField ?? field,
    valueGetter: (params: ValueGetterParams): number =>
      maybeExtractEntityIds(params.data[field]).length,
    type: "integerColumn",
    cellRenderer: "relationshipAggregationCellRenderer",
    cellRendererParams: {
      crp: {
        shouldRenderTag: (props) => !!props.value,
        entityName,
        getEntityIds: (props: ICellRendererParams): number[] =>
          maybeExtractEntityIds(props.data[field]),
      },
    },
    ...restProps,
  };
}

export function useSumColDef({
  headerName,
  baseEntityName,
  entityName,
  field,
  type,
  foreignField,
  shouldRenderTag,
}: {
  headerName: string;
  baseEntityName: EntityName;
  entityName: EntityName;
  field: string;
  type?: string;
  foreignField: string | string[];
  shouldRenderTag?: ShouldRenderTag;
}): AgColDef {
  const entitiesById = useStoreState((s) => s[entityName].rowsById);
  const getEntityIds = (props: ICellRendererParams): number[] =>
    maybeExtractEntityIds(props.data[field]);

  return {
    headerName,
    field,
    valueGetter: (params: ValueGetterParams): number =>
      sumEntitiesByField(
        maybeExtractEntityIds(params.data[field]),
        entitiesById,
        foreignField
      ),
    type: type ?? "floatColumn",
    cellRenderer: "relationshipAggregationCellRenderer",
    cellRendererParams: {
      crp: {
        shouldRenderTag:
          shouldRenderTag ?? ((props) => !!getEntityIds(props).length),
        baseEntityName,
        entityName,
        getEntityIds,
      },
    },
  };
}

export function getRelatedColDef({
  baseEntityName,
  entityName,
  entityIdsField,
  shouldRenderTag,
  getTagIntent,
  ...restProps
}: {
  headerName: string;
  baseEntityName: EntityName;
  entityName: EntityName;
  field: string;
  entityIdsField: string;
  type: string;
  getTagIntent?: any;
  shouldRenderTag?: ShouldRenderTag;
}): AgColDef {
  const getEntityIds = (props: ICellRendererParams): number[] =>
    maybeExtractEntityIds(props.data[entityIdsField]);

  return {
    cellRenderer: "relationshipAggregationCellRenderer",
    cellRendererParams: {
      crp: {
        shouldRenderTag:
          shouldRenderTag ?? ((props) => !!getEntityIds(props).length),
        baseEntityName,
        entityName,
        getEntityIds,
        getTagIntent,
      },
    },
    ...restProps,
  };
}

function sumEntitiesByField(
  entityIds: TableRowId[] | Set<TableRowId>,
  entitiesById: { [p: string]: TableRow; [p: number]: TableRow },
  foreignField: string | string[]
): number {
  if (_.isSet(entityIds)) {
    entityIds = Array.from(entityIds);
  }
  const entities = entityIds.map((id) => entitiesById[id]);
  const values = entities.map((entity) => getField(entity, foreignField));
  return _.sum(values);
}

export function RelatedEntitiesCountTag(
  props: Omit<RelatedEntitiesTagProps, "children">
) {
  return (
    <RelatedEntitiesTag {...props}>
      {integerVF({ value: _.size(props.entityIds) })}
    </RelatedEntitiesTag>
  );
}

export function useRelatedsSum(
  entityName: EntityName,
  entityIds: TableRowId[] | Set<TableRowId>,
  foreignField: string | string[]
): number {
  const entitiesById = useStoreState((s) => s[entityName].rowsById);
  return sumEntitiesByField(entityIds, entitiesById, foreignField);
}

export function RelatedEntitiesSumTag(
  props: Omit<RelatedEntitiesTagProps, "children"> & {
    foreignField: string | string[];
  }
) {
  const { entityName, entityIds, foreignField } = props;

  const value = useRelatedsSum(entityName, entityIds, foreignField);

  const formatter = getFormatter(props.type) ?? floatVF;
  const formattedValue = formatter(value);

  return <RelatedEntitiesTag {...props}>{formattedValue}</RelatedEntitiesTag>;
}

export function textVF(params): string {
  const value = params?.value ?? params;
  return _.toString(value);
}

export function fractionVF(params): string {
  const value = params?.value ?? params;
  if (_.isArray(value)) {
    return value.map((v) => (_.isNumber(v) ? integerVF(v) : v)).join(" / ");
  } else {
    return _.toString(value);
  }
}

export function getFormatter(
  type: string
): null | { (value: Params<any>): null | string } {
  switch (type) {
    case "float":
    case "floatColumn":
      return floatVF;
    case "money":
    case "moneyColumn":
      return moneyVF;
    case "date":
    case "dateColumn":
      return dateVF;
    case "timestamp":
    case "timestampColumn":
      return timestampVF;
    case "percentage":
    case "percentageColumn":
      return percentageVF;
    case "fraction":
    case "fractionColumn":
      return fractionVF;
    case "integer":
    case "integerColumn":
      return integerVF;
    case "linkedText":
    case "linkedTextColumn":
    case "text":
    case "textColumn":
      return textVF;
    case "boolean":
    case "booleanColumn":
      return booleanVF;
    default:
      return null;
  }
}

export function RelatedEntitiesTag({
  baseEntityName,
  entityName,
  entityIds,
  finalizeColDefs,
  finalizeRowData,
  position,
  intent = Intent.PRIMARY,
  children,
}: RelatedEntitiesTagProps): JSX.Element {
  return (
    <Popover
      autoFocus={false}
      usePortal={true}
      boundary={"viewport"}
      // position={"bottom"}
      position={position ?? Position.BOTTOM}
      interactionKind={PopoverInteractionKind.HOVER}
      hoverCloseDelay={750}
      content={
        <RelatedEntitiesTag_PopoverContent
          baseEntityName={baseEntityName}
          entityName={entityName}
          entityIds={entityIds}
          finalizeColDefs={finalizeColDefs}
          finalizeRowData={finalizeRowData}
        />
      }
    >
      <Tag
        interactive={true}
        intent={intent}
        css={`
          font-size: 14px;
          padding: 3px 7px;
        `}
      >
        {children}
      </Tag>
    </Popover>
  );
}

function RelatedEntitiesTag_PopoverContent({
  baseEntityName,
  entityName,
  entityIds,
  finalizeColDefs,
  finalizeRowData,
}: RelatedEntitiesProps): JSX.Element {
  const entityIdsSet = useMemo(() => new Set(entityIds), [entityIds]);
  const EntityTableComponent = getEntityTableComponent(entityName);

  const finalizeRowDataNested = (rowData) => {
    let finalized = rowData.filter((row) => entityIdsSet.has(row.id));
    if (finalizeRowData) {
      finalized = finalizeRowData(finalized);
    }
    return finalized;
  };

  return (
    <div
      css={`
        padding: 0;
        width: 50vw;
      `}
    >
      <EntityTableComponent
        finalizeRowData={finalizeRowDataNested}
        finalizeColDefs={finalizeColDefs}
        height={"40vh"}
        fullScreenEnabled={false}
      />
    </div>
  );
}

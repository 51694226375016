import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import React from "react";
import { Button, Callout, Dialog, Drawer, H4, Icon } from "@blueprintjs/core";
import { useStoreActions, useStoreState } from "../hooks/ep";
import { useClipboard } from "use-clipboard-copy";
import "styled-components/macro";

export function OpenMailtoDialog() {
  const isOpen = useStoreState((s) => s.misc.isMailtoAlertOpen);
  const handleOpenMailtoLinkInsideApp = useStoreActions(
    (a) => a.misc.handleOpenMailtoLinkInsideApp
  );
  const handleOpenMailtoLinkOutsideApp = useStoreActions(
    (a) => a.misc.handleOpenMailtoLinkOutsideApp
  );
  const handleCloseMailtoLinkOverlays = useStoreActions(
    (a) => a.misc.handleCloseMailtoLinkOverlays
  );

  return (
    <Dialog
      canEscapeKeyClose
      canOutsideClickClose
      //@ts-ignore
      onClose={handleCloseMailtoLinkOverlays}
      className={"bp3-dark"}
      icon="envelope"
      title={"Open Email Template"}
      isOpen={isOpen}
      usePortal={true}
    >
      <div className="bp3-dialog-body">
        Where would you like to open this email template?
      </div>
      <div className="bp3-dialog-footer">
        <div className="bp3-dialog-footer-actions">
          <Button
            intent={Intent.PRIMARY}
            icon={"document"}
            // @ts-ignore
            onClick={handleOpenMailtoLinkInsideApp}
          >
            Copy from Customax
          </Button>
          <Button
            intent={Intent.PRIMARY}
            icon={"send-message"}
            // @ts-ignore
            onClick={handleOpenMailtoLinkOutsideApp}
          >
            Open in Email Client
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

function MailtoDrawerContent({
  includeMailtoLinkButton,
}: {
  includeMailtoLinkButton: boolean;
}) {
  const mailtoLink = useStoreState((s) => s.misc.mailtoLinkForDrawer);

  if (!mailtoLink) {
    return null;
  }
  const qp = urlQueryGetter(mailtoLink);
  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
      `}
    >
      <p>
        Use the blue clipboard icons to copy the email fields below into your
        email client (e.g. Outlook, Gmail).
      </p>
      {includeMailtoLinkButton && (
        <CopyMailtoLinkButton mailtoLink={mailtoLink} />
      )}
      <MailtoPart name={"To"} value={qp.to} />
      <MailtoPart name={"CC"} value={qp.cc} />
      <MailtoPart
        name={"BCC"}
        value={qp.bcc}
        helperText={"(please ensure this address is BCC'd)"}
      />
      <MailtoPart name={"Subject"} value={qp.subject} />
      <MailtoPart name={"Body"} value={qp.body} />
      {/*<p>qp: "{JSON.stringify(qp, undefined, 2)}"</p>*/}
    </div>
  );
}

function CopyMailtoLinkButton({ mailtoLink }: { mailtoLink: string }) {
  const cb = useClipboard({
    copiedTimeout: 500,
  });
  return (
    <div
      css={`
        margin-bottom: 24px;
      `}
    >
      <Button
        large
        fill={true}
        icon="clipboard"
        minimal
        onClick={() => cb.copy(mailtoLink)}
        intent={Intent.PRIMARY}
      >
        {cb.copied ? "Copied!" : "Mailto Link"}
      </Button>
    </div>
  );
}

function MailtoPart({
  name,
  helperText,
  value,
}: {
  name: string;
  helperText?: string;
  value: string | null;
}): null | JSX.Element {
  const cb = useClipboard({
    copiedTimeout: 500,
  });

  if (!value) {
    return null;
  }

  return (
    <Callout
      key={name}
      intent={Intent.NONE}
      icon={
        <Button
          icon={<Icon icon="clipboard" iconSize={Icon.SIZE_LARGE} />}
          interactive
          onClick={() => cb.copy(value)}
          minimal
          intent={Intent.PRIMARY}
        />
      }
      className={"bp3-callout-icon"}
      css={`
        margin-bottom: 24px;
        & > .bp3-button:first-child {
          position: absolute;
          left: 10px;
          top: 10px;
          margin-left: -5px;
          margin-top: -5px;
          padding: 0;
          & > .bp3-icon {
            padding: 0;
          }
        }
      `}
    >
      <H4
        css={`
          display: inline-block;
        `}
      >
        {cb.copied ? "Copied!" : name}
      </H4>
      {helperText && !cb.copied && (
        <span
          className={"bp3-text-muted bp3-text-small"}
          css={`
            margin-left: 16px;
          `}
        >
          {helperText}
        </span>
      )}
      <p
        css={`
          margin-top: 4px;
          white-space: pre-wrap;
        `}
      >
        {value}
      </p>
    </Callout>
  );
}

export function MailtoDrawer() {
  const isOpen = useStoreState((s) => s.misc.isMailtoDrawerOpen);
  const onClose = useStoreActions((a) => a.misc.handleCloseMailtoLinkOverlays);
  return (
    <Drawer
      canEscapeKeyClose={true}
      isCloseButtonShown={true}
      isOpen={isOpen}
      // @ts-ignore
      onClose={onClose}
      className="bp3-dark"
      size={Drawer.SIZE_LARGE}
      title={"Personalized Email Template"}
      usePortal={true}
    >
      <div
        css={`
          padding: 24px;
          overflow-y: auto;
        `}
      >
        <MailtoDrawerContent includeMailtoLinkButton={false} />
      </div>
    </Drawer>
  );
}

function urlQueryGetter(url) {
  //array to store params
  const qParam = { to: null, cc: null, bcc: null, subject: null, body: null };

  //parse url
  const toEmailAddress = decodeURIComponent(
    url.substring(url.indexOf(":") + 1, url.indexOf("?"))
  );
  const query = url.substring(url.indexOf("?") + 1);
  const query_items = query.split("&");
  for (let i = 0; i < query_items.length; i++) {
    const [key, value] = query_items[i].split("=");
    const decodedValue = decodeURIComponent(value);
    if (qParam[key]) {
      qParam[key] = [qParam[key], decodedValue].join(",");
    } else {
      qParam[key] = decodedValue;
    }
  }
  qParam["to"] = toEmailAddress;
  return qParam;
}

import React from "react";
import { IconName, Spinner } from "@blueprintjs/core";
import { useEnsureData } from "../../helpers/use-ensure-data";
import { TextInput } from "../../components/drawer-forms/inputs/text-input";
import { VALID_NEW_USER_ROLES } from "../../store/model-me";
import { VALID_TIMEZONES } from "../../common/valid-timezones";
import { useFieldName2Value, useSetField } from "../../store/model-drawer-form";

export function CreateNewCmaxUser_FormContent({ activeField, setActiveField }) {
  const loading = useEnsureData("cmax_user");
  const fieldName2Value = useFieldName2Value();
  const setField = useSetField();
  return loading ? (
    <Spinner />
  ) : (
    <Inner
      fieldName2Value={fieldName2Value}
      setField={setField}
      activeField={activeField}
      setActiveField={setActiveField}
    />
  );
}

export function Inner({
  fieldName2Value,
  setField,
  activeField,
  setActiveField,
}) {
  const textInputConfigs: {
    name: string;
    label: string;
    helperText: string;
    leftIcon: IconName;
  }[] = [
    {
      name: "email",
      label: "Email",
      helperText:
        "The new Customax user's email address. A Customax user with this email address must not already exist",
      leftIcon: "envelope",
    },
    {
      name: "role",
      label: "Role",
      helperText: `The new Customax user's role. One of the following: ${VALID_NEW_USER_ROLES.join(
        ", "
      )}`,
      leftIcon: "hat",
    },
    {
      name: "first_name",
      label: "First Name",
      helperText:
        "The new Customax user's first name. This is how they will be addressed by Customax in the app and in emails",
      leftIcon: "id-number",
    },
    {
      name: "last_name",
      label: "Last Name",
      helperText: "The new Customax user's last name",
      leftIcon: "barcode",
    },
    {
      name: "timezone",
      label: "Timezone",
      helperText: `The new Customax user's timezone. One of the following: ${VALID_TIMEZONES.join(
        ", "
      )}`,
      leftIcon: "globe",
    },
  ];
  return (
    <>
      {textInputConfigs.map(({ name, label, helperText, leftIcon }) => (
        <TextInput
          key={name}
          helperText={helperText}
          label={label}
          name={name}
          leftIcon={leftIcon}
          value={fieldName2Value[name]}
          onValueChange={(value) => setField({ name, value })}
          activeField={activeField}
          setActiveField={setActiveField}
        />
      ))}
    </>
  );
}

import _ from "lodash";
import React from "react";
import { Intent, Tag, Button, AnchorButton } from "@blueprintjs/core";
import { ICellRendererParams } from "@ag-grid-community/core/dist/es6/rendering/cellRenderers/iCellRenderer";
import { TableRow } from "../../../../store/table-model-factory";
import { AgColDef } from "../../types";
import { useHistory } from "react-router";

export function LinkedTextCellRenderer(
  props: ICellRendererParams
): JSX.Element {
  const { value, valueFormatted, data, colDef, node } = props;
  const text = valueFormatted ?? value;

  // if (colDef.field === "pub_sale_pair_last_published_at") {
  //   console.log(
  //     "LinkedTextCellRenderer",
  //     value,
  //     valueFormatted,
  //     typeof value,
  //     typeof valueFormatted,
  //     data
  //   );
  // }

  // @ts-ignore
  const { __pinnedRowCellRenderer, __linkIsInternal } = colDef;

  if (__pinnedRowCellRenderer && node.isRowPinned()) {
    return __pinnedRowCellRenderer(props);
  }

  let link = getLinkForText(props);

  // noinspection JSConstructorReturnsPrimitive
  if (!link) {
    return <span>{text}</span>;
  } else if (__linkIsInternal) {
    return <InternalLink text={text} link={link} />;
  } else {
    return (
      <a
        href={link}
        // eslint-disable-next-line react/jsx-no-target-blank
        target={"_blank"}
      >
        {text}
      </a>
    );
  }
}

export function InternalLink({ text, link }): JSX.Element {
  const history = useHistory();
  return (
    <AnchorButton
      small={true}
      intent={Intent.PRIMARY}
      minimal
      css={`
        font-size: 14px !important;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      `}
      onClick={() => history.push(link)}
    >
      {text}
    </AnchorButton>
  );
}

export function getLinkForText(params: ICellRendererParams) {
  const { data, colDef }: { data: TableRow; colDef?: AgColDef } = params;
  const {
    // @ts-ignore
    __linkField,
    // @ts-ignore
    __linkValueGetter,
    // @ts-ignore
    __linkIsNotAnEmail,
    // @ts-ignore
    __linkIsInternal,
  } = colDef;

  let link;
  if (!_.isNil(__linkField)) {
    link = data[__linkField];
  } else if (!_.isNil(__linkValueGetter)) {
    link = __linkValueGetter(params);
  }

  if (!link) {
    return link;
  }

  if (link && !__linkIsInternal && !link.startsWith("http")) {
    link = "//" + link;
  }

  if (!__linkIsNotAnEmail) {
    link = inferMailtoLinks(link);
  }

  return link;
}

export const emailPatt =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateEmail(email) {
  return emailPatt.test(email);
}

export function inferMailtoLinks(url) {
  if (url && validateEmail(url)) {
    return `mailto:${url}`;
  } else {
    return url;
  }
}

import React from "react";
import { useMe } from "../../hooks/useMe";
import { useFullScreenModeEnabled } from "../../store/model-misc";
import { LAYOUT_HEADER_HEIGHT_PX } from "../../styles/constants";
import { Col, Layout, Row } from "antd";
import { UserSettings } from "./user-settings";
import "styled-components/macro";
import { MyBreadcrumbs } from "./my-breadcrumbs";

const { Header } = Layout;

const NAVBAR_LOGO_FILEPATH = "/customax-logo.png";

export function MyHeader() {
  const me = useMe();
  if (useFullScreenModeEnabled()) {
    return null;
  }
  return (
    <Header
      data-testid="wrapper_header"
      css={`
        height: ${LAYOUT_HEADER_HEIGHT_PX};
        vertical-align: center;
      `}
    >
      <div
        css={`
          //width: 100%;
          //height: 100%;
          text-align: center;
          position: absolute;
          left: calc(50vw - (121.66px / 2));
          //top: 16px;
        `}
      >
        <img
          src={NAVBAR_LOGO_FILEPATH}
          height="16px"
          alt="Customax Logo"
          css={`
            //z-index: 1000000000;
            cursor: pointer;
          `}
          onClick={() => window.location.reload()}
        />
      </div>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        css={`
          height: 100%;
          margin: 0 -36px 0 -24px;
        `}
      >
        <Col>{!me.loading && me.success && <MyBreadcrumbs />}</Col>
        <Col>
          <div
            css={`
              display: flex;
              align-items: center;
            `}
          >
            {!me.loading && me.success && <UserSettings />}
          </div>
        </Col>
      </Row>
    </Header>
  );
}

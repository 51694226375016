import { UserRole } from "../../store/model-me";

export const CAN_DO_LEVEL2_WRITES__USER_ROLES = [
  UserRole.SAXECAP_DEV,
  UserRole.ADMIN_READ_WRITE,
  UserRole.OBSERVER_READ_WRITE,
];
export const CAN_DO_LEVEL2_WRITES__USER_EMAILS = [];

export const CAN_DO_LEVEL3_WRITES__USER_ROLES = [
  UserRole.SAXECAP_DEV,
  UserRole.ADMIN_READ_WRITE,
];
export const CAN_DO_LEVEL3_WRITES__USER_EMAILS = [];
export const CAN_DO_LEVEL4_WRITES__USER_ROLES = [UserRole.SAXECAP_DEV];
export const CAN_DO_LEVEL4_WRITES__USER_EMAILS = [];

export function isAuthorizedForLevelWrites(
  level: WriteLevel,
  myRole: UserRole,
  myEmail: string
): boolean {
  if (typeof level === "number") {
    if (level === 1) {
      return true;
    } else if (level === 2) {
      return (
        CAN_DO_LEVEL2_WRITES__USER_ROLES.includes(myRole) ||
        CAN_DO_LEVEL2_WRITES__USER_EMAILS.includes(myEmail)
      );
    } else if (level === 3) {
      return (
        CAN_DO_LEVEL3_WRITES__USER_ROLES.includes(myRole) ||
        CAN_DO_LEVEL3_WRITES__USER_EMAILS.includes(myEmail)
      );
    } else if (level === 4) {
      return (
        CAN_DO_LEVEL4_WRITES__USER_ROLES.includes(myRole) ||
        CAN_DO_LEVEL4_WRITES__USER_EMAILS.includes(myEmail)
      );
    }
  }
  return false;
}

type WriteLevelNumber = 1 | 2 | 3 | 4;
export type WriteLevel = WriteLevelNumber;

import React from "react";
import styled from "styled-components/macro";
import { Popover } from "@blueprintjs/core";

function PopoverWrapper({ content, children, className, ...rest }) {
  return (
    <Popover {...rest} content={content} portalClassName={className}>
      {children}
    </Popover>
  );
}

export const StyledPopoverWrapper = styled(PopoverWrapper)`
  &&& * {
    z-index: 1000;
  }
`;

import React from "react";
import "styled-components/macro";
import {
  Classes,
  PopoverInteractionKind,
  Tag,
  Tooltip,
  UL,
} from "@blueprintjs/core";
import _ from "lodash";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import styled from "styled-components/macro";
import { extractTagStrings } from "./tagsColumnFilter";
import { ICellRendererParams } from "@ag-grid-community/core/dist/es6/rendering/cellRenderers/iCellRenderer";

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > * {
    margin-right: 16px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

const StyledTag = styled(Tag)`
  &&&&& {
    background-color: rgba(138, 155, 168, 0.1) !important;
  }
`;

export function TagsCellRenderer(props: ICellRendererParams): JSX.Element {
  let {
    value: tags,
    data,
    node,
    //@ts-ignore
    crp,
    colDef,
  } = props;

  // @ts-ignore
  const { __pinnedRowCellRenderer } = colDef;

  if (__pinnedRowCellRenderer && node.isRowPinned()) {
    return __pinnedRowCellRenderer(props);
  }

  crp = crp || {};
  const tagIcons = crp.tagIcons || {};
  const tagDisplayNames = crp.tagDisplayNames || {};
  const tagSortOrder = crp.tagSortOrder || null;
  const TooltipContentHeader = crp.TooltipContentHeader || (({ data }) => null);
  const TooltipContent = crp.TooltipContent;
  const tooltipProps = crp.tooltipProps ?? {};

  let trueTagIds = extractTagStrings(tags || "");

  if (tagSortOrder) {
    trueTagIds = _.sortBy(trueTagIds, (tagId) => tagSortOrder.indexOf(tagId));
  } else {
    trueTagIds.sort();
  }

  if (tagSortOrder === null) {
    return (
      <Tooltip
        targetClassName={"line-clamp-target"}
        boundary="viewport"
        content={
          TooltipContent ? (
            <TooltipContent {...props} />
          ) : (
            <div
              className={`${Classes.DARK} ${Classes.POPOVER_CONTENT} ${Classes.RUNNING_TEXT}`}
              css={`
                opacity: 1;
                width: fit-content;
                // padding: 0 10px 0 0 !important;
                padding: 0 !important;
              `}
            >
              <TooltipContentHeader data={data} />
              <UL>
                {trueTagIds.map((tagId) => (
                  <li key={tagId} className="tags-tooltip-list-item">
                    {tagId}
                  </li>
                ))}
              </UL>
            </div>
          )
        }
        {...tooltipProps}
      >
        {trueTagIds.join(", ")}
      </Tooltip>
    );
  }

  return (
    <StyledTag fill large minimal round intent={Intent.NONE}>
      <StyledDiv>
        {tagSortOrder.map((tagId) => {
          const Icon = tagIcons?.[tagId];
          let intent: Intent;
          let displayName = tagDisplayNames[tagId];
          return (
            <MyTag
              key={tagId}
              id={tagId}
              data={data}
              icon={Icon ? <Icon intent={intent} /> : null}
              displayName={displayName}
              isTrue={trueTagIds.includes(tagId)}
            />
          );
        })}
      </StyledDiv>
    </StyledTag>
  );
}

type IxnKindT =
  | typeof PopoverInteractionKind.HOVER
  | typeof PopoverInteractionKind.HOVER_TARGET_ONLY;

function getTooltipProps(data, id, displayName) {
  data = data || {};

  let content = displayName || id;
  let interactionKind: IxnKindT = PopoverInteractionKind.HOVER_TARGET_ONLY;
  let hoverCloseDelay = 0;
  return { content, interactionKind, hoverCloseDelay };
}

const EmptyTagSpan = styled.span`
  visibility: hidden;
`;

export function MyTag({ id, icon, data, displayName, isTrue = true }) {
  const tooltipProps = getTooltipProps(data, id, displayName);

  if (icon && isTrue) {
    return (
      <Tooltip boundary={"viewport"} {...tooltipProps}>
        {icon}
      </Tooltip>
    );
  } else if (icon) {
    return <EmptyTagSpan>{icon}</EmptyTagSpan>;
  } else {
    return <span className={"bp3-text"}>{displayName || id}</span>;
  }
}

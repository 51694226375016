import { TableModel, tableModelFactory, TableRow } from "./table-model-factory";
import {
  Actions,
  Computed,
  computed,
  Thunk,
  thunk,
  thunkOn,
  ThunkOn,
} from "easy-peasy";
import {
  makeAuthorizedPatchRequestToBackend,
  makeUrl,
} from "../helpers/backendApi";
import { toaster } from "../toaster";
import _ from "lodash";
import {
  ActionPayload_CreateNewCmaxUser,
  createNewCmaxUser,
  NewCmaxUser,
} from "../cmax-users/user-actions/create-new-cmax-user";
import { StoreModel } from "./model";
import { Injections } from "./store-injections";

export interface CmaxUserModel extends TableModel {
  rowData: Computed<CmaxUserModel, TableRow[], StoreModel>;
  rowDataById: Computed<
    CmaxUserModel,
    { [key: string]: TableRow; [key: number]: TableRow },
    StoreModel
  >;
  handlePatchCmaxUser: Thunk<
    CmaxUserModel,
    [TableRow, { [key: string]: any }],
    Injections,
    StoreModel
  >;
  handleCreateNewCmaxUser: Thunk<
    CmaxUserModel,
    NewCmaxUser,
    Injections,
    StoreModel
  >;
  onSourceDataChange: ThunkOn<CmaxUserModel, Injections, StoreModel>;
}

export function getCmaxUserModel(): CmaxUserModel {
  return {
    ...tableModelFactory("cmax_user", "cmax_users", (row) => row.id),
    handlePatchCmaxUser: thunk(
      async (
        actions: Actions<CmaxUserModel>,
        [row, patchPayload],
        { getState, dispatch }
      ) => {
        const state = getState();
        const rowId = state.GET_ROW_ID(row);
        const oldRow = state.getRow(rowId);
        actions.upsertRow({ ...oldRow, ...patchPayload });
        try {
          const { data: newRow } = await makeAuthorizedPatchRequestToBackend({
            url: makeUrl(`cmax_users/${rowId}`),
            data: patchPayload,
            axiosConfig: undefined,
          });
          toaster.success("Customax user update succeeded", 2);
          actions.upsertRow(newRow);
        } catch (e) {
          if (oldRow) {
            actions.upsertRow(oldRow);
          } else {
            actions.deleteRow(rowId);
          }
          toaster.error("Customax user update failed -- please try again");
        }
      }
    ),
    rowData: computed(
      [(s) => s.initialData, (s) => s.relatedRowsMap],
      (initialData, relatedRowsMap) => {
        return [...initialData];
      }
    ),
    rowDataById: computed([(s) => s.rowData], (rowData) =>
      Object.fromEntries(rowData.map((r) => [r.id, r]))
    ),
    handleCreateNewCmaxUser: thunk(
      async (
        actions: Actions<CmaxUserModel>,
        payload: NewCmaxUser,
        { getState, dispatch }
      ) => {
        const actionPayload: ActionPayload_CreateNewCmaxUser = {
          email: payload.email,
          formData: payload,
        };
        const state = getState();
        const existingEmails = state.initialData.map((row) =>
          row.email.toLowerCase()
        );

        if (existingEmails.includes(actionPayload.email.toLowerCase())) {
          toaster.warning(
            `Failed to create new Customer user -- a Customer user with that email address already exists`
          );
          throw Error();
        }

        const maxCurrentCmaxUserId = _.max(
          state.initialData.map((row) => row.id)
        ) as number;
        const rowId = maxCurrentCmaxUserId + 1;
        const newCmaxUserFakeId = { ...actionPayload.formData, id: rowId };
        actions.upsertRow(newCmaxUserFakeId);

        try {
          await createNewCmaxUser(actionPayload);

          toaster.success(`Successfully created new Customax user`, 2);
        } catch (e) {
          actions.deleteRow(rowId);
          toaster.warning(
            `Failed to create new Customax user -- please try again`
          );
          throw Error();
        }
      }
    ),
    onSourceDataChange: thunkOn(
      (actions, storeActions) => [
        actions.handlePatchCmaxUser,
        actions.handleCreateNewCmaxUser,
      ],
      async (actions, target, { getStoreState }) => {
        // @ts-ignore
        await actions.handleFetchInitialData();
      }
    ),
  };
}

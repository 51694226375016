import { PopoverPosition, Tooltip } from "@blueprintjs/core";
import React from "react";
import "styled-components/macro";

export function TooltipLabel({
  labelText,
  tooltipText,
  tooltipPosition = PopoverPosition.TOP,
}: {
  labelText: string;
  tooltipText: string;
  tooltipPosition?: PopoverPosition;
}): JSX.Element {
  return (
    <div
      css={`
        width: fit-content;
      `}
    >
      <Tooltip
        content={
          <div
            css={`
              max-width: 200px;
            `}
          >
            {tooltipText}
          </div>
        }
        position={tooltipPosition}
      >
        {labelText}
      </Tooltip>
    </div>
  );
}

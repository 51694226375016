import { AgColDef } from "../../components/AgTable/types";
import { useMyTimezone } from "../../entities/helpers/authorization";

export function useCmaxUserLastVisitedTimestampCD(
  overrides?: AgColDef
): AgColDef {
  const myTz = useMyTimezone();
  return getCmaxUserLastVisitedTimestampCD(myTz, overrides);
}
export function getCmaxUserLastVisitedTimestampCD(
  tz: string,
  overrides?: AgColDef
): AgColDef {
  return {
    headerName: "Last Visited",
    field: "latest_flask_request_timestamp",
    type: "timestampColumn",
    __timezone: tz,
    headerTooltip: "When the Customax user most recently visited Customax",
    ...(overrides ?? {}),
  };
}

import { AutoComplete } from "antd";
import styled from "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import _ from "lodash";
import React, { useMemo } from "react";
import { timeit } from "../../helpers/timing";

const { Option } = AutoComplete;

const OptionContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

function gatherOptions(rowData, colDefs) {
  const stringCounts = gatherStrings(rowData, colDefs);
  const sortedStrings = _.sortBy(
    Object.entries(stringCounts),
    ([str, cnt]) => -cnt
  );
  return sortedStrings.map(([str, cnt], idx) => (
    <Option
      key={idx}
      value={str}
      title={str}
      data-lowertrim={_.toLower(_.trim(str))}
    >
      <OptionContent>
        {str} <span>{cnt}</span>
      </OptionContent>
    </Option>
  ));
}

const WORD_BREAK_CHARS = new Set([" ", "_", "-", ",", "("]);

function filterOptions(options, value) {
  const lowerTrimValue = _.toLower(_.trim(value));

  if (lowerTrimValue.length === 0) {
    return [];
  }

  const filteredOptions = [];
  options.every((opt) => {
    if (filteredOptions.length >= 50) return false;
    const ov = opt.props["data-lowertrim"];
    const foundIndex = ov.indexOf(lowerTrimValue);
    let isMatch;
    if (foundIndex === -1) {
      isMatch = false;
    } else if (foundIndex === 0) {
      isMatch = true;
    } else {
      const charBeforeFoundIndex = ov[foundIndex - 1];
      isMatch =
        WORD_BREAK_CHARS.has(charBeforeFoundIndex) && ov !== lowerTrimValue;
    }
    if (isMatch) {
      filteredOptions.push(opt);
    }
    return true;
  });
  return filteredOptions;
}

export function useOptions(rowData, colDefs, value) {
  const options = useMemo(
    () => gatherOptions(rowData, colDefs),
    [rowData, colDefs]
  );

  return useMemo(
    () => (value.length === 0 ? [] : filterOptions(options, value)),
    [options, value]
  );
}

const gatherStrings = timeit((rowData, colDefs) => {
  if (!rowData || !_.size(rowData)) {
    return [];
  }

  const searchableColTypes = [
    "textColumn",
    "categoryColumn",
    "tagsColumn",
    "linkedTextColumn",
  ];
  const searchableColDefs = colDefs.filter(({ type }) =>
    searchableColTypes.includes(type)
  );

  const counts = {};

  if (typeof rowData.reify === "function") {
    // console.log("123abc");
    const sampledRowData =
      rowData.numRows() > 10000 ? rowData.sample(10000) : rowData;
    searchableColDefs.forEach(({ field, type }) => {
      const valueCounts = sampledRowData.groupby(field).count();
      const valueCol = valueCounts.column(field);
      const countCol = valueCounts.column("count");
      valueCounts.indices(false).forEach((ix) => {
        const value = valueCol.get(ix);
        const count = countCol.get(ix);
        // if (_.isNil(value)) return null;
        // const s = _.trim(_.toString(value));
        const s = value;
        // if (s.length) {
        // counts.set(s, (counts.get(s) ?? 0) + count);
        counts[s] = (counts[s] || 0) + count;
        // }
      });
    });
  } else {
    // console.log("nononono");
    rowData.forEach((row) => {
      searchableColDefs.forEach(({ field, type, valueGetter }) => {
        let fieldValue = valueGetter ? valueGetter({ data: row }) : row[field];
        if (type === "tagsColumn" && _.isArray(fieldValue)) {
          fieldValue = fieldValue.join(", ");
        }
        if (fieldValue === null) return null;
        const s = _.trim(_.toString(fieldValue));
        if (s.length) {
          // counts.set(s, (counts.get(s) ?? 0) + 1);
          counts[s] = (counts[s] || 0) + 1;
        }
        // if (_.isString(fieldValue))
      });
    });
  }
  return counts;
}, "gatherStrings");

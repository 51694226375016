import React from "react";
import { Menu } from "antd";
import "styled-components/macro";

const MenuItem = Menu.Item;

export function LeftNavMenuItem({ urlPath, title, icon, history }) {
  return (
    <Menu.Item
      key={urlPath || title}
      title={title}
      onClick={() => urlPath && history.push(urlPath)}
      data-testid={`leftnav-${urlPath}`}
    >
      {icon}
    </Menu.Item>
  );
}

export function LeftNavMenuItemNested({ urlPath, title, icon, history }) {
  return (
    <MenuItem
      key={urlPath || title}
      title={title}
      onClick={() => urlPath && history.push(urlPath)}
      data-testid={`leftnav-${urlPath}`}
      icon={icon}
      css={`
        &&& .ant-icon,
        &&& .bp3-icon {
          margin-right: 8px;
        }
        //display: flex;
        //justify-content: space-between;
        //align-items: center;
      `}
    >
      {title}
    </MenuItem>
  );
}

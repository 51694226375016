import React from "react";
import "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import AG_TABLE_THEMES from "../../styles/ag-table-themes";
// eslint-disable-next-line no-restricted-imports
import { ThemeProvider } from "styled-components";

export function AgTableThemeProvider({ children }) {
  return (
    <ThemeProvider theme={{ mode: "dark", ...AG_TABLE_THEMES["dark"] }}>
      {children}
    </ThemeProvider>
  );
}

export function timeit(fn, name: string): any {
  return (...args) => {
    const t0 = performance.now();
    let ret;
    try {
      ret = fn(...args);
    } catch (e) {
      console.error(e.stack);
      throw e;
    }
    const t1 = performance.now();
    console.log(
      `Call to name="${name}" took ${Math.round(t1 - t0)} milliseconds.`
    );
    return ret;
  };
}

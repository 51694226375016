import { useUserTimezone } from "../components/wrapper/data-freshness-helpers";
import moment from "moment-timezone";

export function useTimeOfDay(): string {
  const userTz = useUserTimezone();
  const userTzHours = moment().tz(userTz).hours();
  if (userTzHours < 12) {
    return "Morning";
  } else if (userTzHours < 17) {
    return "Afternoon";
  } else {
    return "Evening";
  }
}

import { action } from "easy-peasy";
import _ from "lodash";

export const actionFunctions = () => ({
  receiveInitialData: (state, payload) => {
    if (_.isObject(state.initialData) && !_.isArray(state.initialData)) {
      state.initialData = { ...state.initialData, ...payload };
    } else {
      state.initialData = payload;
    }
  },
  receiveInitialDataSlice: (state, payload) => {
    state.initialData = { ...state.initialData, ...payload };
  },
});

export const actions = () => ({
  clear: action((state) => {
    state.initialData = state.nullData;
    state.initialDataReceived = false;
  }),
  setFetchQueryParams: action((state, value) => {
    state.fetchQueryParams = value;
  }),
  receiveInitialDataSlice: action(actionFunctions().receiveInitialDataSlice),
  markInitialDataReceived: action((state) => {
    state.initialDataReceived = true;
  }),
  markInitialDataNotReceived: action((state) => {
    state.initialDataReceived = false;
  }),
  markInitialDataLoading: action((state) => {
    state.initialDataLoading = true;
  }),
  markInitialDataNotLoading: action((state) => {
    state.initialDataLoading = false;
  }),
});

import _ from "lodash";
import React from "react";
import { inferMailtoLinks } from "../linked-text/cell-renderer";

export function MultiLinkedTextCellRenderer({ value: texts, data, colDef }) {
  let links;
  if (!_.isNil(colDef.__linksField)) {
    links = data[colDef.__linksField];
  } else {
    links = colDef.__linksValueGetter(data);
  }

  const hyperlinkText = (text, elementIndex) => {
    const link = links[elementIndex];

    let inner;

    // noinspection JSConstructorReturnsPrimitive
    if (!link) {
      inner = <span key={text}>{text}</span>;
    } else {
      inner = (
        <a
          key={text}
          href={colDef.__linksAreNotEmails ? link : inferMailtoLinks(link)}
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
        >
          {text}
        </a>
      );
    }

    const isNotLast = elementIndex < texts.length - 1;
    return (
      <span key={text}>
        {inner}
        {isNotLast && <span>,&nbsp;</span>}
      </span>
    );
  };

  const elems = texts.map(hyperlinkText);
  return <span>{elems}</span>;
}

import { submitUserActionPostRequest } from "../../user-actions/user_actions";

export async function createNewCmaxUser(
  action_payload: ActionPayload_CreateNewCmaxUser
) {
  return await submitUserActionPostRequest(
    "create_new_cmax_user",
    action_payload
  );
}

export interface NewCmaxUser {
  role: string;
  email: string;
  timezone: string;
  first_name: string;
  last_name: string;
}

export interface ActionPayload_CreateNewCmaxUser {
  email: string;
  formData: NewCmaxUser;
}

import { Action, action, Thunk, thunk, ThunkOn, thunkOn } from "easy-peasy";
import {
  makeAuthorizedPatchRequestToBackend,
  makeUrl,
} from "../helpers/backendApi";
import { actionFunctions } from "./commonActions";
import { StoreModel } from "./model";
import { toaster } from "../toaster";
import { thunks } from "./commonThunks";
import { Injections } from "./store-injections";

export enum UserRole {
  SAXECAP_DEV = "saxecap_dev",
  ADMIN_READ_WRITE = "admin_read_write",
  ADMIN_READ_ONLY = "admin_read_only",
  OBSERVER_READ_WRITE = "observer_read_write",
  OBSERVER_READ_ONLY = "observer_read_only",
  DEFAULT = "default",
}

export const VALID_NEW_USER_ROLES = [
  UserRole.ADMIN_READ_ONLY,
  UserRole.ADMIN_READ_WRITE,
  UserRole.OBSERVER_READ_WRITE,
  UserRole.OBSERVER_READ_ONLY,
];
export const VALID_UPDATED_USER_ROLES = [
  UserRole.OBSERVER_READ_WRITE,
  UserRole.OBSERVER_READ_ONLY,
];
export const ROW_LEVEL_PERMISSIONS_ROLES = [];

export interface MeData {
  id: number;
  role: UserRole;
  first_name: string;
  last_name: string;
  nickname: string;
  email: string;
  timezone: string;
}

export interface MeModel {
  NAME: string;
  INITIAL_DATA_ENDPOINT: string;
  initialData: MeData | null;
  initialDataReceived: boolean;
  initialDataLoading: boolean;
  hasPrintedError: boolean;
  //
  setHasPrintedError: Action<MeModel>;
  resetData: Action<MeModel>;
  onLogout: ThunkOn<MeModel, Injections, StoreModel>;
  receiveTimezone: Action<MeModel, any>;
  receiveInitialData: Action<MeModel, any>;
  //
  synchronizeWithFirebaseAuth: Thunk<MeModel, any, Injections, StoreModel>;
  handleUpdateTimezone: Thunk<MeModel, any, Injections, StoreModel>;
  maybeHandleFetchInitialData: Thunk<MeModel, any, Injections, StoreModel>;
}

export const meModel: MeModel = {
  NAME: "me",
  INITIAL_DATA_ENDPOINT: "me",
  initialData: null,
  initialDataReceived: false,
  initialDataLoading: false,
  hasPrintedError: false,
  //
  // Actions
  //
  setHasPrintedError: action((s) => {
    s.hasPrintedError = true;
  }),
  resetData: action((state) => {
    state.initialData = null;
    state.initialDataReceived = false;
    state.initialDataLoading = false;
    state.hasPrintedError = false;
  }),
  onLogout: thunkOn(
    (__, storeActions) => storeActions.firebase.logout,
    (actions) => {
      actions.resetData();
    }
  ),
  handleUpdateTimezone: thunk(async (actions, payload, { getState }) => {
    const url = makeUrl("me");
    const data = { timezone: payload };
    const resp = await makeAuthorizedPatchRequestToBackend({
      url,
      data,
      axiosConfig: undefined,
    });
    actions.receiveTimezone(resp.data);
  }),
  receiveTimezone: action((state, payload) => {
    state.initialData.timezone = payload.timezone;
  }),
  receiveInitialData: action(actionFunctions().receiveInitialData),
  //
  // Thunks
  //
  synchronizeWithFirebaseAuth: thunk(
    async (
      actions,
      { userIsSignedIn, userMustSignIn },
      { getState, dispatch, getStoreState }
    ) => {
      if (userMustSignIn) {
        actions.resetData();
      } else if (userIsSignedIn && !getState().initialDataReceived) {
        try {
          await dispatch.me.maybeHandleFetchInitialData(null);
        } catch (err) {
          toaster.error(
            "We're having some trouble signing you in. " +
              "Please try reloading the page and " +
              "email team@saxecap.com if these issues persist. Thanks!"
          );
          // @ts-ignore
          actions.setHasPrintedError(true);
          throw err;
        }
      }
    }
  ),
  maybeHandleFetchInitialData: thunks().maybeHandleFetchInitialData,
};

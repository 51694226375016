import { useMe } from "../hooks/useMe";
import { useMemo } from "react";
import { MeData, UserRole } from "../store/model-me";
import {
  DEFAULT_ROUTES_BY_ROLE,
  ROLE_ALLOWED_ROUTES_PATTS,
  Routes,
  DEFAULT_ROUTES_PATT,
  USER_EXTRA_ALLOWED_ROUTES_PATTS,
} from "../pages-routes";

interface AuthorizationState {
  loading: boolean;
  isAuthorized: boolean;
}

export function allowedRoutesForRole(role: UserRole) {
  return ROLE_ALLOWED_ROUTES_PATTS[role];
}

export function allowedRoutesForUser(userEmail: string) {
  return USER_EXTRA_ALLOWED_ROUTES_PATTS[userEmail] || DEFAULT_ROUTES_PATT;
}

const amIAuthorized = (meData: MeData, route: string): boolean => {
  const roleAllowedRoutesPatt = allowedRoutesForRole(meData.role);
  const userAllowedRoutesPatt = allowedRoutesForUser(meData.email);
  return roleAllowedRoutesPatt.test(route) || userAllowedRoutesPatt.test(route);
};

export function useIsAuthorized(route: string): AuthorizationState {
  const me = useMe();
  const meInitialData: MeData = me.initialData;
  const isAuthorized: boolean = me.success
    ? amIAuthorized(meInitialData, route)
    : false;
  return { loading: me.loading, isAuthorized };
}

export function useMyDefaultRoute(): Routes {
  const me = useMe();
  const myRole = me.initialData?.role;
  let ret;
  if (myRole) {
    ret = DEFAULT_ROUTES_BY_ROLE[myRole] ?? Routes.DEFAULT;
  } else {
    ret = null;
  }
  // console.log(ret);
  return ret;
}

export function useAuthorizedRoutes(): Set<string> {
  const me = useMe();
  return useMemo(
    () =>
      !me.success
        ? new Set([])
        : new Set(
            Object.values(Routes).filter((route) =>
              amIAuthorized(me.initialData, route)
            )
          ),
    [me.initialData, me.success]
  );
}

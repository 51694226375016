import React from "react";
import { Button, Menu, Position } from "@blueprintjs/core";
import { Tooltip } from "antd";
import "styled-components/macro";
import { StyledPopoverWrapper } from "../../../../popover-wrapper";

export function TakeActionButtonCellRenderer({ data, crp }) {
  return (
    <span>
      <StyledPopoverWrapper
        canEscapeKeyClose
        content={
          <Menu large>
            {crp.popoverMenuItems.map((Comp, idx) => (
              <Comp key={idx} data={data} />
            ))}
          </Menu>
        }
        position={Position.RIGHT_BOTTOM}
      >
        <Tooltip title="Actions" placement="topLeft" mouseEnterDelay={1.5}>
          <Button
            minimal
            disabled={crp.getDisabled ? crp.getDisabled(data) : false}
            small
            icon="take-action"
            large={false}
            intent="primary"
          />
        </Tooltip>
      </StyledPopoverWrapper>
    </span>
  );
}

import { AgColDef } from "../../components/AgTable/types";
import { useCanDoLevelWrites } from "../../entities/helpers/authorization";
import { useStoreActions } from "../../hooks/ep";
import _ from "lodash";
import { UserRole, VALID_UPDATED_USER_ROLES } from "../../store/model-me";
import { toaster } from "../../toaster";
import { CellValueChangedEvent } from "@ag-grid-community/core/dist/es6/events";

export function useRoleColDef(): AgColDef {
  const canDoLevel2Writes = useCanDoLevelWrites(2);
  const canDoLevel4Writes = useCanDoLevelWrites(4);
  const handlePatchUser = useStoreActions(
    (a) => a.cmax_user.handlePatchCmaxUser
  );
  let colDef = {
    headerName: "Role",
    field: "role",
    type: "categoryColumn",
  };
  if (canDoLevel2Writes) {
    colDef = {
      ...colDef,
      editable: (params) => true,
      cellClass: (params) => {
        return [
          "bp3-minimal",
          "bp3-tag",
          "bp3-interactive",
          // "bp3-round",
          "bp3-small",
        ];
      },
      valueParser: (params) => _.trim(params.newValue),
      valueSetter: (params) => {
        const validUpdatedUserRoles = canDoLevel4Writes
          ? [
              UserRole.SAXECAP_DEV,
              UserRole.ADMIN_READ_WRITE,
              UserRole.ADMIN_READ_ONLY,
              ...VALID_UPDATED_USER_ROLES,
            ]
          : VALID_UPDATED_USER_ROLES;
        const isValid = validUpdatedUserRoles.includes(params.newValue);

        if (isValid) {
          params.data.role = params.newValue;
          return true;
        } else {
          toaster.error(
            `Please enter one of the following: ${validUpdatedUserRoles.join(
              ", "
            )}`
          );
          return false;
        }
      },
      onCellValueChanged: async (ev: CellValueChangedEvent) => {
        await handlePatchUser([
          ev.data,
          {
            role: ev.newValue,
          },
        ]);
      },
    };
  }
  return colDef;
}

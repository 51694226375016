export type Message = any;

export class AssertionError extends Error {}
export function assert(
  condition: boolean | (() => boolean),
  message?: Message
): asserts condition {
  if (typeof condition === "function") {
    condition = condition();
  }
  console.assert(condition, message);
  if (!condition) {
    throw new AssertionError(JSON.stringify(message));
  }
}

export function assertType<T>(value: unknown): asserts value is T {
  assert(value !== undefined);
}

export function assertNumTrue(values: boolean[], numTrue: number) {
  assert(values.filter((v) => v).length === numTrue);
}

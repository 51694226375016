import React from "react";
import { ITooltipParams } from "@ag-grid-community/core";
import { Classes, UL } from "@blueprintjs/core";
import "styled-components/macro";
import _ from "lodash";

const LI_SENTINEL = "<<<li>>>";

export class BasicBp3Tooltip extends React.Component<ITooltipParams> {
  getReactContainerClasses() {
    return ["custom-tooltip", Classes.TOOLTIP, Classes.DARK];
  }

  render() {
    const { value } = this.props;
    let content: string = _.unescape(value);
    let listItems;
    let listItemsJsonified;
    if (content.includes(LI_SENTINEL)) {
      [content, listItemsJsonified] = content.split(LI_SENTINEL);
      listItems = JSON.parse(listItemsJsonified);
    }
    return (
      <ContentPrefixedUL
        content={content}
        listItems={listItems}
        element={(v, ix) => <li key={ix}>{v}</li>}
      />
    );
  }
}
export function ContentPrefixedUL(props: {
  content: string;
  listItems: any;
  element: (v, ix) => JSX.Element;
  className?: string;
}) {
  const element = props.element ?? ((v, ix) => <li key={ix}>{v}</li>);
  return (
    <div
      className={[
        Classes.DARK,
        Classes.POPOVER_CONTENT,
        Classes.RUNNING_TEXT,
        props.className,
      ]
        .filter((v) => !!v)
        .join(" ")}
      css={`
        opacity: 1;
        width: fit-content;
      `}
    >
      <p>{props.content}</p>
      {props.listItems && <UL>{props.listItems.map(element)}</UL>}
    </div>
  );
}

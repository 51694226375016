import React, { useCallback } from "react";
import { useBoolean } from "../../helpers/useBoolean";
import useDimensions from "react-use-dimensions";
import {
  Button,
  Classes,
  InputGroup,
  Intent,
  Tooltip,
} from "@blueprintjs/core";
import { StyledFormGroup } from "../forms-stuff/styled-form-group";
import "styled-components/macro";

export function PasswordInput({
  password,
  setPassword,
  activeField,
  setActiveField,
}) {
  const onPasswordInputChange = useCallback(
    (ev) => {
      ev.preventDefault();
      setPassword(ev.target.value);
    },
    [setPassword]
  );
  const showPassword = useBoolean(false);

  // For IE11 :(
  const [eyeBtnRef, eyeBtnDims] = useDimensions();

  const eyeButton = (
    <span
      ref={eyeBtnRef}
      css={`
        padding: 0;
        height: 100%;
      `}
    >
      <Tooltip content={`${showPassword.value ? "Hide" : "Show"} Password`}>
        <Button
          icon={showPassword.value ? "eye-open" : "eye-off"}
          intent={Intent.WARNING}
          minimal={true}
          large={true}
          onClick={showPassword.toggle}
          css={`
            height: ${eyeBtnDims.height}px;
          `}
        />
      </Tooltip>
    </span>
  );

  return (
    <StyledFormGroup
      labelFor="password"
      label="Password"
      helperText={
        "Please use the password issued to you by an administrator. " +
        "Please email team@saxecap.com if you have not received your password."
      }
      activeField={activeField}
    >
      <InputGroup
        data-testid="signin_password_input"
        name="password"
        className={Classes.ELEVATION_2}
        type={showPassword.value ? "text" : "password"}
        rightElement={eyeButton}
        fill={true}
        large={true}
        leftIcon="lock"
        value={password}
        onChange={onPasswordInputChange}
        placeholder="Enter your password.."
        onFocus={() => setActiveField("password")}
      />
    </StyledFormGroup>
  );
}

const VALID_PASSWORD_PATT = /^.+$/;

export function isValidPassword(password) {
  return VALID_PASSWORD_PATT.test(password);
}

export interface ObjectWithAnyValues {
  [name: string]: any;
}

export interface ObjectWithStringValues {
  [name: string]: string;
}

export function parseFieldsToString(
  obj: ObjectWithAnyValues
): ObjectWithStringValues {
  return Object.keys(obj).reduce((parsedObj, key) => {
    parsedObj[key] = String(obj[key]);
    return parsedObj;
  }, {});
}

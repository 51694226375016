import { useEffect } from "react";
import { useBoolean } from "../helpers/useBoolean";
import {
  firebaseAnalytics,
  firebasePerformance,
} from "../helpers/fbase/fbCore";

export function useFirebaseAnalytics(meData) {
  const configured = useBoolean(false);
  const myEmail = meData?.email ?? null;
  const myRole = meData?.role ?? null;

  useEffect(() => {
    if (myEmail && myRole && !configured.value) {
      try {
        firebaseAnalytics.setUserId(myEmail, { global: true });
        firebaseAnalytics.setUserProperties(
          { ...meData, user_id: myEmail },
          { global: true }
        );
        firebaseAnalytics.setAnalyticsCollectionEnabled(true);

        // Look in the <head> tag in `public/index.html` for
        // the "first-input-delay" polyfill <script> tag
        firebasePerformance.instrumentationEnabled = true;

        configured.setTrue();
      } catch (e) {
        console.error("Failed in useFirebaseAnalytics. Error:", e);
      }
    }
  }, [myEmail, myRole]);
}

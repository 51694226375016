import axios from "axios";
import { getFirebase } from "react-redux-firebase";
import _ from "lodash";
import * as localforage from "localforage";
// import { DataFrame } from "apache-arrow/compute/dataframe";
import * as aq from "arquero";
import * as arrow from "apache-arrow";
import * as simdjson from "simdjson";
import { timeit } from "./timing";
import * as fflate from "fflate";
// import { getArrowTableSummary } from "./getArrowTableSummary";
// const { tableFromIPC } = require("apache-arrow");

// const parseSerializedContent = timeit((serializedContent, resp) => {
const parseSerializedContent = timeit(async (serializedContent, resp) => {
  const respMimetype = resp.headers.get("Content-Type");
  const url = resp.url;
  let ret;
  if (respMimetype === "application/json") {
    if (_.isString(serializedContent)) {
      // ret = await parseJson(serializedContent);
      ret = parseJson(serializedContent);
    } else if (_.isArrayBuffer(serializedContent)) {
      // ret = await parseJson(await gunzip(serializedContent));
      ret = parseJson(gunzip(serializedContent));
    } else {
      ret = serializedContent;
    }
  } else if (respMimetype === "application/vnd.apache.arrow.file") {
    // const aq = await import("arquero");
    // const arrow = await import("apache-arrow");

    ret = aq.fromArrow(arrow.tableFromIPC(serializedContent));
    ret.forEach = (cb) => {
      ret.scan((rowIndex, tableData, scanStop) => {
        const rowObj = ret.object(rowIndex);
        cb(rowObj);
      });
    };
    ret.length = ret._nrows;
  } else if (respMimetype === "application/parquet") {
    const pq = await import("parquet-wasm");
    // const pq = await import("parquet-wasm/bundler/arrow2");
    // console.log(pq);
    // console.log(pq.readParquet);
    // console.log(serializedContent);
    const parquetTable = pq.readParquet(new Uint8Array(serializedContent));
    // const parquetTable = pq.readParquet2(serializedContent);
    // console.log(parquetTable);
    const arrowTable = arrow.tableFromIPC(parquetTable);
    // console.log(arrowTable);
    ret = aq.fromArrow(arrowTable);
    ret.forEach = (cb) => {
      ret.scan((rowIndex, tableData, scanStop) => {
        const rowObj = ret.object(rowIndex);
        cb(rowObj);
      });
    };
    ret.length = ret._nrows;
  } else {
    const msg = `respMimetype=${respMimetype} is not supported`;
    console.error(msg);
    throw new Error(msg);
  }
  return ret;
}, "parseSerializedContent");

const ALWAYS_USE_STORED_DATA_WHEN_ABLE = false;

let axiosInterceptorsSet = { value: false };

// noinspection JSUnresolvedVariable
const isDevEnv =
  window.Cypress ||
  !process.env.NODE_ENV ||
  process.env.NODE_ENV === "development";

export const baseBackendUrl = () =>
  isDevEnv ? "http://localhost.charlesproxy.com:5000/api" : "/api";

export function makeUrl(endpoint, queryParams) {
  let prefix = "/";
  return (
    baseBackendUrl() +
    prefix +
    _.trim(endpoint, "/") +
    getQueryStringFromQueryParams(queryParams)
  );
}

export function getQueryStringFromQueryParams(queryParams) {
  return !queryParams
    ? ""
    : "?" +
        Object.entries(queryParams)
          // .filter(([k, v]) => !_.isNil(v))
          .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
          .join("&");
}

function maybeSetAxiosInterceptors() {
  if (axiosInterceptorsSet.value) {
    return;
  }

  axios.interceptors.request.use(async function (config) {
    const fb_ = getFirebase();
    const auth_ = fb_.auth();
    const currentUser = auth_.currentUser;
    if (!currentUser) {
      console.error("No current user to get ID Token header from");
      return;
    }
    const idToken = await currentUser.getIdToken(false);
    if (!idToken) {
      console.error("Current user's ID Token is blank -- can't set header");
      return;
    }
    config.headers["X-ID-TOKEN"] = idToken;

    return config;
  });
}

export async function makeAuthorizedGetRequestToBackend({ url, axiosConfig }) {
  return await makeAuthorizedRequestToBackend({
    method: "get",
    url,
    axiosConfig,
  });
}

const getPrevContentHash = timeit(async (url, verbose = true) => {
  let prevContentHash = null;
  try {
    prevContentHash = await localforage.getItem("content_hash|" + url);
    // This code runs once the value has been loaded
    // from the offline store.
    if (verbose) {
      console.log({ prevContentHash });
    }
  } catch (getReqError) {
    // This code runs if there were any errors.
    console.error({ getReqError });
  }
  return prevContentHash;
}, "getPrevContentHash");

export function getRequestHeaders(prevContentHash) {
  if (prevContentHash) {
    return { "X-PREV-CONTENT-HASH": prevContentHash };
  } else {
    return {};
  }
}

function getResponseContentHash(resp) {
  const responseHeaders = resp.headers;
  return (
    responseHeaders.get("x-curr-content-hash") ||
    responseHeaders.get("X-CURR-CONTENT-HASH")
  );
}

export async function makeAuthorizedGetRequestToBackend2({ url, axiosConfig }) {
  const prevContentHash = await getPrevContentHash(url);

  let data;
  if (isDevEnv && ALWAYS_USE_STORED_DATA_WHEN_ABLE && prevContentHash) {
    data = await localforage.getItem("content|" + url);
  } else {
    if (prevContentHash) {
      axiosConfig = axiosConfig || {};
      axiosConfig.headers = {
        ...(axiosConfig.headers || {}),
        ...getRequestHeaders(prevContentHash),
      };
    }
    const resp = await makeAuthorizedRequestToBackend({
      method: "get",
      url,
      axiosConfig,
    });

    const currContentHash = getResponseContentHash(resp);
    if (!currContentHash) {
      console.log(
        `X-CURR-CONTENT-HASH header is empty in response for url=${url}`
      );
      console.log(resp);
      data = resp.data;
    } else if (currContentHash === prevContentHash) {
      data = await localforage.getItem("content|" + url);
    } else {
      data = resp.data;
      // noinspection ES6MissingAwait
      localforage.setItem("content|" + url, data);
      // noinspection ES6MissingAwait
      localforage.setItem("content_hash|" + url, currContentHash);
    }
  }

  if (_.isString(data)) {
    // console.log({ type: typeof data, data });
    try {
      // noinspection JSValidateTypes
      return JSON.parseMore(data);
    } catch (e) {
      // Throws an exception if data isn't a valid JSON
      // in which case we return the string
      return data;
    }
  }
  return data;
}

export const DEFAULT_RESPONSE_FORMAT = "json";

// const parseJson = timeit(async (serializedContent) => {
const parseJson = timeit((serializedContent) => {
  let ret;
  try {
    // const simdjson = await import("simdjson");
    ret = simdjson.parse(serializedContent);
  } catch (err) {
    console.log(`simdjson.parse failed. err=${err}`);
  }

  if (_.isUndefined(ret)) {
    try {
      // noinspection JSValidateTypes
      ret = JSON.parseMore(serializedContent);
    } catch (err) {
      console.error(`JSON.parseMore failed. err=${err}`);
    }
  }
  if (_.isUndefined(ret)) {
    ret = serializedContent;
  }
  return ret;
}, "parseJson");

// const gunzip = timeit(async (arrBuf) => {
const gunzip = timeit((arrBuf) => {
  // const fflate = await import("fflate");
  const compressed = new Uint8Array(arrBuf);
  const decompressed = fflate.decompressSync(compressed);
  const ret = fflate.strFromU8(decompressed);
  // console.log({ compressed, decompressed, ret, arrBuf });
  return ret;
}, "gunzip");

const loadSerializedContent = timeit(async (url) => {
  const contentItemKey = "content|" + url;
  console.log(
    `Loading serialized content from localforage for contentItemKey=${contentItemKey}`
  );
  const serializedContent = await localforage.getItem(contentItemKey);
  console.log(
    `Loaded serialized content with length=${serializedContent.length} from localforage for contentItemKey=${contentItemKey}`
  );
  return serializedContent;
}, "loadSerializedContent");

async function handleResponse(url, resp, prevContentHash, serializedContent) {
  const t0 = performance.now();
  log_response(resp);
  // const url = resp.url;
  const respHeaders = resp.headers;
  const respMimetype = respHeaders.get("Content-Type");
  const currContentHash = getResponseContentHash(resp);

  const contentItemKey = "content|" + url;
  const contentHashItemKey = "content_hash|" + url;

  if (!currContentHash) {
    console.log(
      `X-CURR-CONTENT-HASH header is empty in response for url=${url} w/ prevContentHash=${prevContentHash}`
    );
    serializedContent = await extractDataFromResponse(resp);
    console.log(
      `Loaded serialized content with length=${serializedContent.length} from the response for url=${url}`
    );
  } else if (currContentHash === prevContentHash) {
    console.log(
      `currContentHash and prevContentHash have same value of ${currContentHash}. Will use data cached in IndexedDB`
    );
  } else {
    console.log(
      `currContentHash and prevContentHash differ. currContentHash=${currContentHash} and prevContentHash=${prevContentHash}`
    );
    serializedContent = await extractDataFromResponse(resp);
    console.log(
      `Loaded serialized content with length=${serializedContent.length} from the response for url=${url}`
    );
    await localforage.setItem(contentItemKey, serializedContent);
    await localforage.setItem(contentHashItemKey, currContentHash);
  }

  let ret = await parseSerializedContent(serializedContent, resp);
  console.log(
    `For response for url=${url}: respMimetype=${respMimetype}, ret.length=${
      ret.length
    }, typeof(ret)=${typeof ret}`
  );

  const t1 = performance.now();
  console.log(
    `handleResponse w/ url=${url} took ${Math.round(t1 - t0)} milliseconds.`
  );

  return ret;
}

export async function makeAuthorizedGetRequestToBackend3({ url }) {
  console.group(`makeAuthorizedGetRequestToBackend3 w/ url=${url}`);
  const t0 = performance.now();

  const fb_ = getFirebase();
  const auth_ = fb_.auth();
  const currentUser = auth_.currentUser;
  const t1 = performance.now();
  if (!currentUser) {
    console.error("No current user to get ID Token header from");
    return;
  } else {
    console.log(
      `Took ${Math.round(
        t1 - t0
      )} milliseconds to get to truthy \`currentUser\`.`
    );
  }
  const idToken = await currentUser.getIdToken(false);
  const t2 = performance.now();
  if (!idToken) {
    console.error("Current user's ID Token is blank -- can't set header");
    return;
  } else {
    console.log(
      `Took ${Math.round(t2 - t0)} milliseconds to get to truthy \`idToken\`.`
    );
  }

  const prevContentHash = await getPrevContentHash(url);

  const myHeaders = new Headers();
  myHeaders.append("pragma", "no-cache");
  myHeaders.append("cache-control", "no-cache");
  myHeaders.append("X-ID-TOKEN", idToken);
  if (prevContentHash) {
    myHeaders.append("X-PREV-CONTENT-HASH", prevContentHash);
  }

  const t3a = performance.now();
  const myInit = {
    headers: myHeaders,
  };
  let resp;
  let serializedContent;
  if (prevContentHash) {
    [resp, serializedContent] = await Promise.all([
      fetch(url, myInit),
      loadSerializedContent(url),
    ]);
  } else {
    serializedContent = null;
    resp = await fetch(url, myInit);
  }
  const t3b = performance.now();
  console.log(
    `Took ${Math.round(
      t3b - t3a
    )} milliseconds to fetch the serialized content asynchronously.`
  );
  console.log(
    `Took ${Math.round(t3b - t0)} milliseconds to get to serialized content.`
  );

  const ret = await handleResponse(
    url,
    resp,
    prevContentHash,
    serializedContent
  );

  const t4 = performance.now();
  console.log(
    `Took ${Math.round(t4 - t0)} milliseconds to run entire function.`
  );

  console.groupEnd();
  return ret;
}

const RESP_FIELDS_TO_LOG = ["ok", "redirected", "status", "statusText", "url"];
const RESP_HEADERS_TO_LOG = [
  "Content-Type",
  "Content-Length",
  "Content-Encoding",
  "X-CURR-CONTENT-HASH",
];

export function log_response(resp) {
  const stringify_field = (name) => `${name}=${resp[name]}`;
  const stringify_header = (name) =>
    `headers.get("${name}")=${resp.headers.get(name)}`;
  const fieldStrings = RESP_FIELDS_TO_LOG.map(stringify_field);
  const headerStrings = RESP_HEADERS_TO_LOG.map(stringify_header);
  const eom = [...fieldStrings, ...headerStrings].join(", ");
  const msg = `Received response with: ${eom}`;
  console.log(msg);
}

async function extractDataFromResponse(resp) {
  const respMimetype = resp.headers.get("Content-Type");
  if (respMimetype === "application/json") {
    return await parseJson(await resp.text());
  } else if (respMimetype === "text/csv") {
    return await resp.text();
  } else if (respMimetype === "application/vnd.apache.arrow.file") {
    return await resp.arrayBuffer();
  } else if (respMimetype === "application/parquet") {
    // return new Uint8Array(await resp.arrayBuffer());
    return await resp.arrayBuffer();
  }
}

export function convertArrowTableToVanillaJS(tbl) {
  return tbl; //.toArray();
}

// eslint-disable-next-line no-unused-vars
function buildProxy(table) {
  const fields = table.schema.fields.reduce((m, d) => {
    m[d.name] = table.getColumn(d.name);
    return m;
  }, {});

  const handler = {
    get: function (obj, prop) {
      if (fields.hasOwnProperty(prop)) {
        return fields[prop].get(obj.__rowIndex__);
      } else {
        return undefined;
      }
    },
    has: function (key) {
      return fields.hasOwnProperty(key);
    },
    ownKeys: function () {
      return Object.keys(fields);
    },
  };

  return function (index) {
    return new Proxy({ __rowIndex__: index }, handler);
  };
}

// eslint-disable-next-line no-unused-vars
function buildObject(table) {
  const fields = table.schema.fields.reduce((m, d) => {
    m[d.name] = table.getColumn(d.name);
    return m;
  }, {});
  const fieldEntries = Object.entries(fields);

  const ctr = function (index) {
    this.__rowIndex__ = index;
    fieldEntries.forEach(([colname, col]) => {
      this[colname] = col.get(index);
    });
  };
  const proto = ctr.prototype;
  Object.defineProperty(proto, "__rowIndex__", {
    value: -1,
    writable: true,
    enumerable: false,
  });
  Object.keys(fields).forEach((name) => {
    Object.defineProperty(proto, name, {
      value: null,
      writable: true,
      enumerable: true,
    });
  });

  // Object.setPrototypeOf(ctr, proto);

  return function (i) {
    return new ctr(i);
  };
}
export function getArqueroTableSummary(arrowTable) {
  const tbl = arrowTable;
  return {
    schema: tbl.schema,
    "schema.fields[].name": tbl.schema.fields.map((f) => f.name),
    "count()": tbl.count(),
    numCols: tbl.numCols,
    byteLength: tbl.byteLength,
    length: tbl.length,
    nullCount: tbl.nullCount,
    type: tbl.type,
    typeId: tbl.typeId,
    metadata: tbl.metadata,
  };
}
export async function makeAuthorizedPatchRequestToBackend({
  url,
  data,
  axiosConfig,
}) {
  return await makeAuthorizedRequestToBackend({
    method: "patch",
    data,
    url,
    axiosConfig,
  });
}

export async function makeAuthorizedPostRequestToBackend({
  url,
  data,
  axiosConfig,
}) {
  return await makeAuthorizedRequestToBackend({
    method: "post",
    data,
    url,
    axiosConfig,
  });
}

export async function makeAuthorizedDeleteRequestToBackend({
  url,
  axiosConfig,
}) {
  return await makeAuthorizedRequestToBackend({
    method: "delete",
    url,
    axiosConfig,
  });
}

export async function makeAuthorizedRequestToBackend({
  method,
  url,
  axiosConfig = {},
  data,
}) {
  // const headers = axiosConfig.headers || {};
  // const idTokenHeaders = await getIdTokenHeaders();
  // await ensureIdTokenHeader();
  // if (!idTokenHeaders["X-ID-TOKEN"]) {
  //   throw Error();
  // }
  // const headers = { ...axiosConfigHeaders, ...idTokenHeaders };
  maybeSetAxiosInterceptors();
  return await axios.request({ url, method, data, ...axiosConfig });
}

// export function makeRequester(method) {
//   return async endpoint => {
//     const { data } = await makeAuthorizedRequestToBackend({
//       method,
//       url: makeUrl(endpoint)
//     });
//     return data;
//   };
// }

// export function useAuthSWR(key, method = "get") {
//   if (!key) throw Error();
//   const { data, error } = useSWR(key, makeRequester(method));
//   if (error) message.error({ function: "useAuthSWR", error }, 10);
//   return data;
// }

import { UserRole } from "../../store/model-me";

export const CAN_DO_LEVEL2_READS__USER_ROLES = [
  UserRole.SAXECAP_DEV,
  UserRole.ADMIN_READ_WRITE,
  UserRole.ADMIN_READ_ONLY,
  UserRole.OBSERVER_READ_WRITE,
  UserRole.OBSERVER_READ_ONLY,
];
export const CAN_DO_LEVEL2_READS__USER_EMAILS = [];
export const CAN_DO_LEVEL3_READS__USER_ROLES = [
  UserRole.SAXECAP_DEV,
  UserRole.ADMIN_READ_WRITE,
  UserRole.ADMIN_READ_ONLY,
  UserRole.OBSERVER_READ_WRITE,
  UserRole.OBSERVER_READ_ONLY,
];
export const CAN_DO_LEVEL3_READS__USER_EMAILS = [];
export const CAN_DO_LEVEL4_READS__USER_ROLES = [
  UserRole.SAXECAP_DEV,
  UserRole.ADMIN_READ_WRITE,
  UserRole.ADMIN_READ_ONLY,
  UserRole.OBSERVER_READ_WRITE,
  UserRole.OBSERVER_READ_ONLY,
];
export const CAN_DO_LEVEL4_READS__USER_EMAILS = [];
export const CAN_DO_LEVEL5_READS__USER_ROLES = [
  UserRole.SAXECAP_DEV,
  UserRole.ADMIN_READ_WRITE,
  UserRole.ADMIN_READ_ONLY,
];
export const CAN_DO_LEVEL5_READS__USER_EMAILS = [];
export const CAN_DO_LEVEL6_READS__USER_ROLES = [UserRole.SAXECAP_DEV];
export const CAN_DO_LEVEL6_READS__USER_EMAILS = [];
export const CAN_VIEW_SPECIFIC_CUSTOMERS__USER_ROLES = [];
export const CAN_VIEW_ALL_CUSTOMERS__USER_ROLES = [
  ...CAN_DO_LEVEL4_READS__USER_ROLES,
];
export const CAN_VIEW_ALL_CUSTOMERS__USER_EMAILS = [
  ...CAN_DO_LEVEL4_READS__USER_EMAILS,
];

export function isAuthorizedForLevelReads(
  level: ReadLevel,
  myRole: UserRole,
  myEmail: string
): boolean {
  if (typeof level === "number") {
    if (level === 1) {
      return true;
    } else if (level === 2) {
      return (
        CAN_DO_LEVEL2_READS__USER_ROLES.includes(myRole) ||
        CAN_DO_LEVEL2_READS__USER_EMAILS.includes(myEmail)
      );
    } else if (level === 3) {
      return (
        CAN_DO_LEVEL3_READS__USER_ROLES.includes(myRole) ||
        CAN_DO_LEVEL3_READS__USER_EMAILS.includes(myEmail)
      );
    } else if (level === 4) {
      return (
        CAN_DO_LEVEL4_READS__USER_ROLES.includes(myRole) ||
        CAN_DO_LEVEL4_READS__USER_EMAILS.includes(myEmail)
      );
    } else if (level === 5) {
      return (
        CAN_DO_LEVEL5_READS__USER_ROLES.includes(myRole) ||
        CAN_DO_LEVEL5_READS__USER_EMAILS.includes(myEmail)
      );
    } else if (level === 6) {
      return (
        CAN_DO_LEVEL6_READS__USER_ROLES.includes(myRole) ||
        CAN_DO_LEVEL6_READS__USER_EMAILS.includes(myEmail)
      );
    }
  } else if (typeof level === "string") {
    if (level === "view_all_customers") {
      return (
        CAN_VIEW_ALL_CUSTOMERS__USER_ROLES.includes(myRole) ||
        CAN_VIEW_ALL_CUSTOMERS__USER_EMAILS.includes(myEmail)
      );
    }
  }
  return false;
}

type ReadLevelNumber = 1 | 2 | 3 | 4 | 5 | 6;
type ReadLevelString = "view_all_customers";
export type ReadLevel = ReadLevelNumber | ReadLevelString;

import moment from "moment-timezone";
import { useMe, useMeData } from "../../hooks/useMe";
import { useStoreActions, useStoreState } from "../../hooks/ep";
import { useEndpoint } from "../../helpers/use-endpoint";
import { useEffect } from "react";

export const STATLINE_NAMES = {
  dataPipeline: "Data Refreshed",
};

export function useDataPipelineDataFreshnessDateTimeString(): string {
  const dts = useStoreState((s) => s.misc.dataPipelineDataFreshness);
  const setDts = useStoreActions((a) => a.misc.setDataPipelineDataFreshness);
  const { loading, data } = useEndpoint<string>("data_refreshed_at", !dts);

  useEffect(() => {
    if (!loading && !dts && !!data) {
      setDts(data);
    }
  }, [loading, data]);

  return useFormattedTimestamp(dts);
}

export function useStatlineContent(dts: string, name: string): string {
  const timezone = useUserTimezone();
  if (dts && timezone) {
    return `${name}: ${formatTimestamp(dts, timezone)}`;
  } else {
    return "";
  }
}

export function useUserTimezone(): null | string {
  const { loading } = useMe();
  const meData = useMeData();
  if (loading || !meData || !meData.timezone) {
    return null;
  } else {
    return meData.timezone;
  }
}

function formatTimestamp(dts: string, timezone: string): string {
  return moment(dts).tz(timezone).format("MM/DD/YY [at] hh:mm A (z)");
}

export function useFormattedTimestamp(dts: string): string {
  const timezone = useUserTimezone();
  if (dts && timezone) {
    return formatTimestamp(dts, timezone);
  } else {
    return "";
  }
}

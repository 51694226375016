import React, { useCallback } from "react";
import _ from "lodash";
import { MyCheckbox, MyCheckboxGroup } from "./styled-checkbox-group";
import "styled-components/macro";
import { useBoolean } from "../../helpers/useBoolean";
import { Card, Classes } from "@blueprintjs/core";
import { MyTag } from "./tags-cell-renderer";
import { SimpleRadio } from "../forms-stuff/simple-radio";

function getAllRowNodes(gridApi) {
  const rowNodes = [];
  gridApi.forEachNode((node) => rowNodes.push(node));
  return rowNodes;
}

const COMMA_PATT = /,\s?/;

export function extractTagStrings(value) {
  if (_.isString(value)) {
    return value.split(COMMA_PATT);
  } else {
    return value;
  }
}

export default class TagsColumnFilter extends React.Component {
  constructor(props) {
    super(props);

    this.valueGetter = props.valueGetter;

    this.fp = props.fp || {};
    this.tagIcons = this.fp.tagIcons || {};
    this.tagSortOrder = this.fp.tagSortOrder || null;
    this.tagDisplayNames = this.fp.tagDisplayNames;
    this.whitelistedTagIds = this.fp.whitelistedTagIds || this.fp.tagSortOrder;

    const allItemCounts = _.countBy(
      getAllRowNodes(props.api)
        .map(this.valueGetter)
        .map(extractTagStrings)
        .flat()
        .filter(
          (v) =>
            v && (!this.whitelistedTagIds || this.whitelistedTagIds.includes(v))
        ),
      (v) => v
    );
    if (this.whitelistedTagIds) {
      this.whitelistedTagIds.forEach((tagId) => {
        allItemCounts[tagId] = allItemCounts[tagId] || 0;
      });
    }
    const customSortKeyGetter = props.colDef?.__customSortKeyGetter;

    const allItemValues = _.sortBy(
      Object.keys(allItemCounts),
      customSortKeyGetter
        ? customSortKeyGetter
        : this.tagSortOrder
        ? (v) => this.tagSortOrder.indexOf(v)
        : (v) => -allItemCounts[v]
    );
    this.state = {
      selectedItemValues: [],
      allItemValues,
      allItemCounts,
      filtrationMode: "all",
    };

    this.onChange = this.onChange.bind(this);
    this.setFiltrationMode = this.setFiltrationMode.bind(this);
  }

  setFiltrationMode(filtrationMode) {
    if (!_.isEqual(this.state.filtrationMode, filtrationMode)) {
      this.setState(
        (state) => ({ ...state, filtrationMode }),
        () => {
          this.props.filterChangedCallback();
        }
      );
    }
  }

  isFilterActive() {
    return _.size(this.state.selectedItemValues) !== 0;
  }

  doesFilterPass(params) {
    const tags = extractTagStrings(this.valueGetter(params.node)) ?? [];
    if (this.isFilterActive()) {
      let numTagMatches = 0;
      const numSelectedTags = _.size(this.state.selectedItemValues);
      const numNodeTags = _.size(tags);
      tags.forEach((tag) => {
        if (this.state.selectedItemValues.includes(tag)) {
          numTagMatches += 1;
        }
      });
      if (this.state.filtrationMode === "all") {
        return numTagMatches === numSelectedTags;
      } else if (this.state.filtrationMode === "any") {
        return numTagMatches > 0;
      } else if (this.state.filtrationMode === "only") {
        return numTagMatches === numNodeTags;
      } else {
        // "exactly"
        return (
          numTagMatches === numNodeTags && numTagMatches === numSelectedTags
        );
      }
    } else {
      return true;
    }
  }

  getModel() {
    return { value: this.state.selectedItemValues };
  }

  setModel(model) {
    console.log("In setModel: ", model);

    const selectedItemValues = model && model.value;
    this.onChange(selectedItemValues, true);
  }

  afterGuiAttached(params) {
    this.focus();
  }

  onNewRowsLoaded() {
    // console.log("onNewRowsLoaded");
  }

  focus() {
    // console.log("filter focus");
    // window.setTimeout(() => {
    //   let container = findDOMNode(this.refs.input);
    //   if (container) {
    //     container.focus();
    //   }
    // });
  }

  componentMethod(message) {
    alert(`Alert from TagsColumnFilter ${message}`);
  }

  onChange(newSelectedItemValues, isFromSetModel = false) {
    console.log("In onChange: ", newSelectedItemValues);

    if (newSelectedItemValues === null) {
      newSelectedItemValues = isFromSetModel ? [] : this.state.allItemValues;
    }
    newSelectedItemValues = newSelectedItemValues.map((v) => `${v}`);
    if (!_.isEqual(this.state.selectedItemValues, newSelectedItemValues)) {
      this.setState(
        (state) => ({ ...state, selectedItemValues: newSelectedItemValues }),
        () => {
          this.props.filterChangedCallback();
        }
      );
    }
  }

  render() {
    return (
      <TagsColumnFilterView
        selectedItemValues={this.state.selectedItemValues}
        allItemValues={this.state.allItemValues}
        allItemCounts={this.state.allItemCounts}
        filtrationMode={this.state.filtrationMode}
        setFiltrationMode={this.setFiltrationMode}
        onChange={this.onChange}
        tagIcons={this.tagIcons}
        tagDisplayNames={this.tagDisplayNames}
      />
    );
  }
}

function getCheckboxTitle(v, nItems, onClickCount) {
  return (
    <span>
      <span>{v}</span>
      <span className="nItems" onClick={onClickCount}>
        {nItems}
      </span>
    </span>
  );
}

function TagsColumnFilterView({
  selectedItemValues,
  allItemValues,
  allItemCounts,
  onChange,
  tagIcons,
  tagDisplayNames,
  filtrationMode,
  setFiltrationMode,
}) {
  const alwaysDisplayCounts = useBoolean(false);
  const onClickCount = useCallback(
    (ev) => {
      ev.preventDefault();
      alwaysDisplayCounts.toggle();
    },
    // eslint-disable-next-line
    [alwaysDisplayCounts.toggle]
  );

  return (
    <Card
      css={`
        border-radius: 0 !important;
        border: transparent !important;
        box-shadow: none !important;

        & .ant-checkbox-group {
          background: unset !important;
          color: unset !important;
        }
        & .ant-checkbox-wrapper {
          color: unset !important;
        }
      `}
    >
      <h5 className={Classes.HEADING}>Select Filter Values</h5>
      <AllAnyRadios
        selectedValue={filtrationMode}
        setSelectedValue={setFiltrationMode}
      />
      <MyCheckboxGroup value={selectedItemValues} onChange={onChange}>
        {allItemValues.map((v) => {
          const nItems = allItemCounts[v];
          const Icon = tagIcons?.[v];
          const title = getCheckboxTitle(
            <MyTag
              id={v}
              icon={Icon ? <Icon /> : null}
              displayName={tagDisplayNames?.[v] ?? v}
            />,
            nItems,
            onClickCount
          );
          return (
            <span
              key={v}
              css={`
                margin-top: 8px;
                & .nItems {
                  font-size: 0.6em;
                  padding: 0.3em !important;
                  text-align: center;
                  vertical-align: middle;
                  background-color: transparent !important;
                  border-radius: 1em;
                  margin-left: 1em;
                  color: transparent !important;
                  border: 1px solid transparent;
                }
                && .nItems.nItems:hover {
                  background-color: rgba(255, 255, 255, 0.2) !important;
                  color: white !important;
                  border: none !important;
                }
                ${alwaysDisplayCounts.value ? "& .nItems" : "&:hover .nItems"} {
                  color: white !important;
                  border: 1px solid rgba(255, 255, 255, 0.2);
                }
              `}
            >
              <MyCheckbox key={v} value={v} title={title} />
            </span>
          );
        })}
      </MyCheckboxGroup>
    </Card>
  );
}

function AllAnyRadios({ selectedValue, setSelectedValue }) {
  return (
    <div
      css={`
        display: flex;
        justify-content: flex-start;
        & > *:first-child {
          margin-right: 12px;
        }
      `}
    >
      <SimpleRadio
        key={"any"}
        value={"any"}
        label={"Any"}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        onFocus={() => {}}
        large={false}
      />
      <SimpleRadio
        key={"all"}
        value={"all"}
        label={"All"}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        onFocus={() => {}}
        large={false}
      />
      <SimpleRadio
        key={"only"}
        value={"only"}
        label={"Only"}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        onFocus={() => {}}
        large={false}
      />
      <SimpleRadio
        key={"exactly"}
        value={"exactly"}
        label={"Exactly"}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        onFocus={() => {}}
        large={false}
      />
    </div>
  );
}

import { AgColDef } from "../../components/AgTable/types";
import { useCanDoLevelWrites } from "../../entities/helpers/authorization";
import { useStoreActions } from "../../hooks/ep";
import { parseBoolean } from "../../common/parse-boolean";
import { toaster } from "../../toaster";
import { CellValueChangedEvent } from "@ag-grid-community/core/dist/es6/events";

export function useIsActiveColDef(): AgColDef {
  const canDoLevel2Writes = useCanDoLevelWrites(2);
  const handlePatchUser = useStoreActions(
    (a) => a.cmax_user.handlePatchCmaxUser
  );
  let colDef = {
    headerName: "Active",
    field: "is_active",
    type: "booleanColumn",
  };
  if (canDoLevel2Writes) {
    colDef = {
      ...colDef,
      editable: (params) => true,
      cellClass: (params) => {
        return [
          "bp3-minimal",
          "bp3-tag",
          "bp3-interactive",
          // "bp3-round",
          "bp3-small",
        ];
      },
      valueParser: (params) => parseBoolean(params.newValue),
      valueSetter: (params) => {
        const isValid = typeof params.newValue === "boolean";
        if (isValid) {
          params.data.is_active = params.newValue;
          return true;
        } else {
          toaster.error("Please submit the word 'true' or the word 'false'");
          return false;
        }
      },
      onCellValueChanged: async (ev: CellValueChangedEvent) => {
        await handlePatchUser([
          ev.data,
          {
            is_active: ev.newValue,
          },
        ]);
      },
    };
  }
  return colDef;
}
